import { Component, Vue } from 'vue-property-decorator';

@Component
export default class DefaultValuesMixin extends Vue {
  get $defaultColWidth() {
    return {
      cols: '12',
      md: '6',
      lg: '4',
      xl: '3',
    };
  }

  get $defaultFormColWidth() {
    return {
      cols: '12',
      md: '6',
      lg: '5',
      xl: '4',
    };
  }
}

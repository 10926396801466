



















































import { Component, Provide, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import VlaanderenLogo from '@/components/layout/VlaanderenLogo.vue';
import AuthService from '@/services/AuthorizationService';
import Breadcrumbs from '@/components/layout/Breadcrumbs.vue';
import DialogCard from '@/components/dialog/DialogCard.vue';

const auth = new AuthService();
const Gebruiker = namespace('Gebruiker');

@Component({
  components: { DialogCard, Breadcrumbs, VlaanderenLogo },
})
export default class Header extends Vue {
  @Provide() isLoggedIn = false;
  @Provide() loading = false;

  created() {
    if (process.env.NODE_ENV === 'development') {
      this.logInAndUpdateGebruiker();
    } else {
      auth.isLoggedIn().then(() => this.logInAndUpdateGebruiker());
    }
  }

  logInAndUpdateGebruiker() {
    this.loading = true;
    this.isLoggedIn = true;
    this.updateGebruiker().finally(() => (this.loading = false));
  }

  @Gebruiker.Getter
  public volledigeNaam!: string;

  @Gebruiker.Getter
  public erkenningsnummer!: string;

  @Gebruiker.Getter
  public doelgroep!: string;

  @Gebruiker.Getter
  public registratierollen!: string;

  @Gebruiker.Getter
  public ondernemingInfo!: string;

  @Gebruiker.Getter
  public isControleur!: boolean;

  @Gebruiker.Action
  public updateGebruiker!: () => Promise<void>;
}

import { LinkInterface } from '@/domain/interface/LinkInterface';

export class DeleteDialogObject {
  naam: string;
  links: LinkInterface;

  constructor(deleteDialogObject?: DeleteDialogObject) {
    this.naam = deleteDialogObject?.naam || '';
    this.links = deleteDialogObject?.links || {};
  }
}

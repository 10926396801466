import { EnumInterface } from '@/domain/interface/intern/enumvalues/EnumInterface';
import axios from 'axios';
import { InputOptionInterface } from '@/domain/interface/form/InputOptionInterface';

function excludeItems(array: EnumInterface[], except: string[] | undefined) {
  return array.filter((item) => !except?.includes(item.code));
}

function fetchEnum(
  name: string,
  except: string[] | undefined = undefined
): Promise<EnumInterface[]> {
  return axios
    .get('/omschrijvingen/' + name)
    .then((response) => response.data)
    .then((data: EnumInterface[]) => excludeItems(data, except));
}

function fetchIndependentEnum(
  name: string,
  except: string[] | undefined = undefined
): Promise<EnumInterface[]> {
  return fetchEnum(name, except);
}

function fetchDependentEnum(
  name: string,
  parentName: string,
  parentCode: string,
  except: string[] | undefined = undefined
) {
  return fetchEnum(parentName).then((data: EnumInterface[]) => {
    return excludeItems(
      data
        .find((item) => item.code === parentCode)
        ?.afhankelijkheden?.find(
          (afhankelijkheid) =>
            afhankelijkheid.veld.toLowerCase() === name.toLowerCase()
        )?.waarden || [],
      except
    );
  });
}

function mapForInput(array: EnumInterface[]): InputOptionInterface[] {
  return array.map((value) => {
    return {
      text: value.omschrijving,
      value: { omschrijving: value.omschrijving, code: value.code },
    };
  });
}

function mapCodes(array: EnumInterface[]) {
  return array.map((value) => value.code);
}

function getEnumFromOmschrijving(array: EnumInterface[], omschrijving: string) {
  return array.find((enumValue) => enumValue.omschrijving === omschrijving);
}

function getEnumFromCode(array: EnumInterface[], code: string) {
  return array.find((enumValue) => enumValue.code === code);
}

function getEnumFromCodeWithoutAfhankelijkheden(
  array: EnumInterface[],
  code: string
) {
  const enumFromCode = getEnumFromCode(array, code);
  if (enumFromCode) {
    delete enumFromCode.afhankelijkheden;
  }
  return enumFromCode;
}

function getEnumFromChildCode(
  array: EnumInterface[],
  childEnumName: string,
  childCode: string
) {
  return array.find((categorie) =>
    categorie.afhankelijkheden
      ?.find((a) => a.veld === childEnumName)
      ?.waarden.find((w) => w.code === childCode)
  );
}

function getDependentEnumFromEnumArray(
  array: EnumInterface[],
  afhankelijkheidVeld: string,
  parentCode: string
) {
  return (
    array
      .find((item) => item.code === parentCode)
      ?.afhankelijkheden?.find(
        (afhankelijkheid) =>
          afhankelijkheid.veld.toLowerCase() ===
          afhankelijkheidVeld.toLowerCase()
      )?.waarden || []
  );
}

export {
  fetchDependentEnum,
  fetchIndependentEnum,
  mapForInput,
  mapCodes,
  getEnumFromOmschrijving,
  getEnumFromCode,
  getEnumFromChildCode,
  getDependentEnumFromEnumArray,
  getEnumFromCodeWithoutAfhankelijkheden,
};

import { render, staticRenderFns } from "./IconLamp.vue?vue&type=template&id=2c14725d&scoped=true&"
import script from "./IconLamp.vue?vue&type=script&lang=ts&"
export * from "./IconLamp.vue?vue&type=script&lang=ts&"
import style0 from "./IconLamp.vue?vue&type=style&index=0&id=2c14725d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c14725d",
  null
  
)

export default component.exports
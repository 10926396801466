



























import { Component, Prop } from 'vue-property-decorator';
import PrimaryButton from '@/components/button/PrimaryButton.vue';
import SecondaryButton from '@/components/button/SecondaryButton.vue';
import { mixins } from 'vue-class-component';
import BaseInputMixin from '@/mixins/inputMixins/base/BaseInputMixin';

@Component({
  components: { SecondaryButton, PrimaryButton },
})
export default class WizardButtonGroup extends mixins(BaseInputMixin) {
  @Prop({ default: 'Volgende' }) readonly nextText!: string;
  @Prop({ default: false }) readonly nextDisabled!: boolean;
  @Prop({ default: true }) readonly nextVisible!: boolean;
  @Prop({ default: 'Annuleren' }) readonly backText!: string;
  @Prop({ default: false }) readonly backDisabled!: boolean;
  @Prop({ default: true }) readonly backVisible!: boolean;
  @Prop(String) readonly name?: string;

  next(): void {
    this.$emit('next');
  }

  back(): void {
    this.$emit('back');
  }

  get nextButtonId() {
    return this.name ? 'next-button-' + this.name : 'next-button';
  }

  get backButtonId() {
    return this.name ? 'back-button-' + this.name : 'back-button';
  }
}

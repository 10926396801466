







































import { Component, Inject, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import RulesMixin from '@/mixins/RulesMixin';
import EnumConstantsMixin from '@/mixins/EnumConstantsMixin';
import SearchInput from '@/components/form/SearchInput.vue';
import MenuButtonCheckbox from '@/components/form/MenuButtonCheckbox.vue';
import AutocompleteInput from '@/components/form/AutocompleteInput.vue';
import CombinedInputWithIconButton from '@/components/form/CombinedInputWithIconButton.vue';
import { namespace } from 'vuex-class';
import ConfigHelper from '@/util/ConfigHelper';
import DateMonthPicker from '@/components/form/DateMonthPicker.vue';
import NumberInput from '@/components/form/NumberInput.vue';
import { Filter } from '@/domain/interface/Filter';
import DatePicker from '@/components/form/DatePicker.vue';
import SidePanelFilter from '@/views/partials/filter/SidePanelFilter.vue';
import FilterService from '@/services/FilterService';
import ActiveFilterIndicator from '@/views/partials/filter/ActiveFilterIndicator.vue';

const Gebruiker = namespace('Gebruiker');

@Component({
  components: {
    ActiveFilterIndicator,
    SidePanelFilter,
    DatePicker,
    NumberInput,
    DateMonthPicker,
    CombinedInputWithIconButton,
    AutocompleteInput,
    MenuButtonCheckbox,
    SearchInput,
  },
})
export default class OpdrachtFilter extends mixins(RulesMixin, EnumConstantsMixin) {
  @Inject('filterService') filterService: FilterService;
  showFilter = false;
  sortAscending = true;
  listView = false;
  filter: Filter = this.filterService.getOpdrachtFilter();
  isLeeromgeving = ConfigHelper.isLeeromgeving();
  @Prop({ default: false }) isImport!: boolean;
  @Prop({ default: false }) inDialog!: boolean;

  goToCertificaten() {
    this.$router.push({
      name: 'certificaten',
    });
  }

  search(searchString: string) {
    this.filter.searchString = searchString;
    this.handleSave(this.filter);
  }

  handleSave(filter: Filter) {
    this.showFilter = false;
    this.filter = filter;
    this.$emit('search', this.filter);
  }

  handleCancel() {
    this.showFilter = false;
  }

  resetFilter() {
    this.filterService.resetOpdrachtFilter();
    this.filter = this.filterService.getOpdrachtFilter();
    this.showFilter = false;
    this.$emit('search', this.filter);
  }

  @Gebruiker.Getter
  public isControleur!: boolean;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class RulesMixin extends Vue {
  get $rule() {
    return {
      required: (v: any) => !!v || this.$t('errors.verplicht'),
      requiredBoolean: (v: boolean) =>
        v !== undefined || this.$t('errors.verplicht'),
      arrayRequired: (v: any) =>
        v?.length > 0 || this.$t('errors.minstens-een'),
      positive: (v: any) => !v || Number(v) >= 0 || this.$t('errors.positief'),
      inclusiveMax: (max: number) => {
        return (v: any) =>
          !v ||
          max == null ||
          Number(v) <= max ||
          this.$t('errors.inclusive-max', { max: max });
      },
      max: (max: number) => {
        return (v: any) =>
          !v ||
          max == null ||
          Number(v) < max ||
          this.$t('errors.max', { max: max });
      },
      inclusiveMin: (min: number) => {
        return (v: any) =>
          !v ||
          min == null ||
          Number(v) >= min ||
          this.$t('errors.inclusive-min', { min: min });
      },
      min: (min: number) => {
        return (v: any) =>
          !v ||
          min == null ||
          Number(v) > min ||
          this.$t('errors.min', { min: min });
      },
      precision: (precision: number) => {
        return (v: any) =>
          !v ||
          !v.split('.')[1] ||
          v.split('.')[1]?.length <= precision ||
          (precision === 0
            ? this.$t('errors.integer')
            : this.$tc('errors.precision', precision));
      },
      maxLength: (max: number) => {
        return (v: string) =>
          !v ||
          max == null ||
          v.length <= max ||
          this.$t('errors.max-length', { max: max });
      },
      eanCode: (v: any) =>
        (v.length === 18 && v.substr(0, 2) === '54' && /^\d+$/.test(v)) ||
        this.$t('errors.ean-code'),
    };
  }
}

import { Component, Vue } from 'vue-property-decorator';

@Component
export default class EnumConstantsMixin extends Vue {
  get $code() {
    return {
      opdrachtFilter: {
        ENERGIEDESKUNDIGE: 'ENERGIEDESKUNDIGE',
        STATUS: 'STATUS',
        BESTEMMING: 'BESTEMMING',
        CATEGORIE: 'CATEGORIE',
      },
      energiestroomType: {
        INKOMENDE_STROOM: 'INKOMENDE_STROOM',
        GEEXPORTEERDE_STROOM: 'GEEXPORTEERDE_STROOM',
        ENERGIEGEBRUIK_BUITEN_EENHEID: 'ENERGIEGEBRUIK_BUITEN_EENHEID',
        LOKALE_PRODUCTIE: 'LOKALE_PRODUCTIE',
      },
      meterType: {
        ANALOOG: 'ANALOOG',
        DIGITAAL: 'DIGITAAL',
      },
      nutsbedrijf: {
        FLUVIUS: 'FLUVIUS',
        ELIA: 'ELIA',
        FLUXYS: 'FLUXYS',
      },
      producent: {
        ELEKTRICITEITSNET: 'ELEKTRICITEITSNET',
        GASNET: 'GASNET',
        KACHEL: 'KACHEL',
        CONDENSERENDE_KETEL: 'CONDENSERENDE_KETEL',
        NIET_CONDENSERENDE_KETEL: 'NIET_CONDENSERENDE_KETEL',
        PV: 'PV',
        WARMTELEVERING: 'EXTERNE_WARMTELEVERING',
        WARMTEPOMP: 'WARMTEPOMP',
        WATERTURBINE: 'WATERTURBINE',
        WINDTURBINE: 'WINDTURBINE',
        WKK: 'WKK',
        ZONNEBOILER: 'ZONNEBOILER',
        RESTWARMTERECUPERATIE: 'RESTWARMTERECUPERATIE',
        COMPRESSIEKOELMACHINE: 'COMPRESSIEKOELMACHINE',
        GEOCOOLING_OPEN_SYSTEEM: 'GEOCOOLING_OPEN_SYSTEEM',
      },
      producentSubtype: {
        WARMTEPOMP_ELEKTRISCH: 'WARMTEPOMP_ELEKTRISCH',
        WARMTEPOMP_GAS_AANDRIJVING: 'WARMTEPOMP_GAS_AANDRIJVING',
        WARMTEPOMP_GAS_ABSORPTIE: 'WARMTEPOMP_GAS_ABSORPTIE',
        STOOKOLIE: 'STOOKOLIE',
        GAS: 'GAS',
        GAS_WARMTEPOMP: 'GAS_WARMTEPOMP',
      },
      energiedrager: {
        AARDGAS_HOOG_CALORISCH: 'AARDGAS_HOOG_CALORISCH',
        AARDGAS_LAAG_CALORISCH: 'AARDGAS_LAAG_CALORISCH',
        ANDERE_ONBEKEND: 'ANDERE_ONBEKEND',
        BIOGAS_GEWONNEN_OP_DE_SITE: 'BIOGAS_GEWONNEN_OP_DE_SITE',
        BUTAAN: 'BUTAAN',
        ELEKTRICITEIT: 'ELEKTRICITEIT',
        LICHTE_STOOKOLIE: 'LICHTE_STOOKOLIE',
        METHAAN: 'METHAAN',
        BIOMASSA_ONBEKEND: 'BIOMASSA_ONBEKEND',
        PELLETS: 'PELLETS',
        PROPAAN: 'PROPAAN',
        STEENKOOL: 'STEENKOOL',
        STUKHOUT: 'STUKHOUT',
        VLOEIBARE_BIOBRANDSTOF_GEWONNEN_OP_DE_SITE: 'VLOEIBARE_BIOBRANDSTOF_GEWONNEN_OP_DE_SITE',
        WATER: 'WATER',
        WATERSTOFGAS: 'WATERSTOFGAS',
        WIND: 'WIND',
        ZON: 'ZON',
        ZWARE_STOOKOLIE: 'ZWARE_STOOKOLIE',
      },
      nrMeeteenheid: {
        KUBIEKE_METERS: 'KUBIEKE_METERS',
        KWH: 'KWH',
        LITER: 'LITER',
      },
      locatieMeetpunt: {
        ELEKTRICITEIT_X: 'ELEKTRICITEIT_X',
        ELEKTRICITEIT_1: 'ELEKTRICITEIT_1',
        ELEKTRICITEIT_2: 'ELEKTRICITEIT_2',
        ELEKTRICITEIT_3: 'ELEKTRICITEIT_3',
        ELEKTRICITEIT_3_APARTE_METING: 'ELEKTRICITEIT_3_APARTE_METING',
        AARDGAS_X: 'AARDGAS_X',
        GAS_1: 'GAS_1',
        GAS_2: 'GAS_2',
        GAS_3: 'GAS_3',
        WARMTE_X: 'WARMTE_X',
        WARMTE_1: 'WARMTE_1',
        WARMTE_2: 'WARMTE_2',
        WARMTE_1_RESTWARMTE: 'WARMTE_1_RESTWARMTE',
        BRANDSTOF_X: 'BRANDSTOF_X',
        BRANDSTOF_1: 'BRANDSTOF_1',
        BRANDSTOF_2: 'BRANDSTOF_2',
        KOUDE_1: 'KOUDE_1',
        KOUDE_2: 'KOUDE_2',
      },
      verlichtingstechnologieType: {
        LED: 'LED',
        BUISVORMIGE_FLUORESCENTIELAMP_TYPE_T_5: 'BUISVORMIGE_FLUORESCENTIELAMP_TYPE_T_5',
        BUISVORMIGE_FLUORESCENTIELAMP_ANDERE: 'BUISVORMIGE_FLUORESCENTIELAMP_ANDERE',
        HOGEDRUK_GASONTLADINGSLAMP: 'HOGEDRUK_GASONTLADINGSLAMP',
        COMPACT_FLUORESCENTIELAMP: 'COMPACT_FLUORESCENTIELAMP',
        GLOEILAMP: 'GLOEILAMP',
        ANDERE: 'ANDERE',
        ONBEKEND: 'ONBEKEND',
        GEEN_VASTE_VERLICHTING: 'GEEN_VASTE_VERLICHTING',
      },
      opwekkerSoort: {
        PV: 'PV',
        WINDTURBINE: 'WINDTURBINE',
        WATERTURBINE: 'WATERTURBINE',
        ZONNEBOILER: 'ZONNEBOILER',
        EXTERNE_WARMTELEVERING: 'EXTERNE_WARMTELEVERING',
        WKK: 'WKK',
        WARMTEPOMP: 'WARMTEPOMP',
        CONDENSERENDE_KETEL: 'CONDENSERENDE_KETEL',
        NIET_CONDENSERENDE_KETEL: 'NIET_CONDENSERENDE_KETEL',
        WARME_LUCHTGENERATOR: 'WARME_LUCHTGENERATOR',
        ELEKTRISCHE_WEERSTANDSVERWARMING: 'ELEKTRISCHE_WEERSTANDSVERWARMING',
        KACHEL: 'KACHEL',
        VENTILATIE: 'VENTILATIE',
        COMPRESSIEKOELMACHINE: 'COMPRESSIEKOELMACHINE',
        GEOCOOLING_OPEN_SYSTEEM: 'GEOCOOLING_OPEN_SYSTEEM',
        THERMISCH_AANGEDREVEN_KOELMACHINE: 'THERMISCH_AANGEDREVEN_KOELMACHINE',
        RESTWARMTERECUPERATIE: 'RESTWARMTERECUPERATIE',
      },
      opwekkerType: {
        CENTRAAL: 'CENTRAAL',
        DECENTRAAL: 'DECENTRAAL',
      },
      warmtepompType: {
        ELEKTRISCHE_WARMTEPOMP: 'ELEKTRISCHE_WARMTEPOMP',
        GASSORPTIEWARMTEPOMP: 'GASSORPTIEWARMTEPOMP',
        WARMTEPOMP_MET_GASAANGEDREVEN_MOTOR: 'WARMTEPOMP_MET_GASAANGEDREVEN_MOTOR',
        GASWARMTEPOMP_TYPE_ONBEKEND: 'GASWARMTEPOMP_TYPE_ONBEKEND',
      },
      verbrandingswaarde: {
        ONDERSTE: 'ONDERSTE',
        BOVENSTE: 'BOVENSTE',
      },
      warmtebron: {
        BUITENLUCHT: 'BUITENLUCHT',
        AFGEVOERDE_VENTILATIELUCHT: 'AFGEVOERDE_VENTILATIELUCHT',
        BUITENLUCHT_GEMENGD_MET_AFVOERLUCHT: 'BUITENLUCHT_GEMENGD_MET_AFVOERLUCHT',
        LUCHT_ONBEKEND: 'LUCHT_ONBEKEND',
        BODEM_WARMTE_TRANSPORTEREND_FLUIDUM: 'BODEM_WARMTE_TRANSPORTEREND_FLUIDUM',
        BODEM_VIA_DIRECTE_VERDAMPING: 'BODEM_VIA_DIRECTE_VERDAMPING',
        BODEM_ONBEKEND: 'BODEM_ONBEKEND',
        WATER: 'WATER',
        ONBEKEND: 'ONBEKEND',
        RESTWARMTE: 'RESTWARMTE',
        LUCHT: 'LUCHT',
      },
      afgiftemedium: {
        BINNENLUCHT: 'BINNENLUCHT',
        TOEGEVOERDE_VENTILATIELUCHT_BUITENLUCHT: 'TOEGEVOERDE_VENTILATIELUCHT_BUITENLUCHT',
        TOEGEVOERDE_VENTILATIELUCHT_EN_HERGEBRUIKTE_LUCHT: 'TOEGEVOERDE_VENTILATIELUCHT_EN_HERGEBRUIKTE_LUCHT',
        WATER: 'WATER',
        GEEN_FLUIDUM: 'GEEN_FLUIDUM',
        ONBEKEND: 'ONBEKEND',
      },
      reversibeleOpwekkerSoortKoeling: {
        COMPRESSIEKOELMACHINE: 'COMPRESSIEKOELMACHINE',
        THERMISCH_AANGEDREVEN_KOELMACHINE: 'THERMISCH_AANGEDREVEN_KOELMACHINE',
        GEOCOOLING: 'GEOCOOLING',
      },
      reversibeleOpwekkerSoortWarmte: {
        WARMTEPOMP: 'WARMTEPOMP',
      },
      uitgaandeEnergieType: {
        WARMTE: 'WARMTE',
        ELEKTRICITEIT: 'ELEKTRICITEIT',
        WARMTE_ELEKTRICITEIT: 'WARMTE_ELEKTRICITEIT',
      },
      schildeelType: {
        GEVEL: 'GEVEL',
        PLAFOND: 'PLAFOND',
        PLAT_DAK: 'PLAT_DAK',
        HELLEND_DAK: 'HELLEND_DAK',
        VLOER: 'VLOER',
        VENSTER_GEVEL: 'VENSTER_GEVEL',
        VENSTER_PLAT_DAK: 'VENSTER_PLAT_DAK',
        VENSTER_HELLEND_DAK: 'VENSTER_HELLEND_DAK',
        LICHTE_GEVEL: 'LICHTE_GEVEL',
        POORT_OF_DEUR: 'POORT_OF_DEUR',
      },
      schildeelOpbouwType: {
        MASSIEF: 'MASSIEF',
        SKELETBOUW: 'SKELETBOUW',
        SKELETBOUW_GEVEL: 'SKELETBOUW_GEVEL',
        SKELETBOUW_HELLEND_DAK: 'SKELETBOUW_HELLEND_DAK',
        ONBEKEND: 'ONBEKEND',
        AFDEKPANEEL_OP_ROOSTER: 'AFDEKPANEEL_OP_ROOSTER',
        RIETEN_DAK: 'RIETEN_DAK',
      },
      profielType: {
        GEEN_PROFIEL: 'GEEN_PROFIEL',
        METAAL_AANWEZIGHEID_THERMISCHE_ONDERBREKING_ONBEKEND: 'METAAL_AANWEZIGHEID_THERMISCHE_ONDERBREKING_ONBEKEND',
        METAAL_ZONDER_THERMISCHE_ONDERBREKING: 'METAAL_ZONDER_THERMISCHE_ONDERBREKING',
        METAAL_MET_THERMISCHE_ONDERBREKING: 'METAAL_MET_THERMISCHE_ONDERBREKING',
        KUNSTSTOF_AANTAL_KAMERS_ONBEKEND: 'KUNSTSTOF_AANTAL_KAMERS_ONBEKEND',
        KUNSTSTOF_1_KAMER: 'KUNSTSTOF_1_KAMER',
        KUNSTSTOF_2_KAMERS_OF_MEER: 'KUNSTSTOF_2_KAMERS_OF_MEER',
        HOUT: 'HOUT',
        HOUT_GT_100_MM: 'HOUT_GT_100_MM',
        HOUT_GT_150_MM: 'HOUT_GT_150_MM',
        RAAMPROFIEL_IN_ONBEKEND_MATERIAAL: 'RAAMPROFIEL_IN_ONBEKEND_MATERIAAL',
      },
      begrenzing: {
        BUITEN: 'BUITEN',
        AOR: 'AOR',
        KELDER: 'KELDER',
        VOLLE_GROND: 'VOLLE_GROND',
        AOR_OF_KELDER: 'AOR_OF_KELDER',
      },
      luchtlaag: {
        AANWEZIG: 'AANWEZIG',
        AFWEZIG: 'AFWEZIG',
        ONBEKEND: 'ONBEKEND',
      },
      onderbrekingIsolatie: {
        AANWEZIG: 'AANWEZIG',
        AFWEZIG: 'AFWEZIG',
        ONBEKEND: 'ONBEKEND',
      },
      opwekkerToepassingType: {
        RUIMTEVERWARMING: 'RUIMTEVERWARMING',
        SWW: 'SWW',
        BEVOCHTIGING: 'BEVOCHTIGING',
        COMFORTKOELING: 'COMFORTKOELING',
        PROCESKOELING: 'PROCESKOELING',
        VENTILATIE: 'VENTILATIE',
        ANDERE: 'ANDERE',
        ZWEMBAD: 'ZWEMBAD',
      },
      isolatieType: {
        SITU: 'SITU',
        FABRIEK: 'FABRIEK',
        REFLECTERENDE_LAAG: 'REFLECTERENDE_LAAG',
      },
      isolatiemateriaal: {
        MW_MINERALE_WOL: 'MW_MINERALE_WOL',
        PUR_PIR_POLYURETHAAN: 'PUR_PIR_POLYURETHAAN',
        EPS_GEEXPANDEERD_POLYSTYREEN: 'EPS_GEEXPANDEERD_POLYSTYREEN',
        XPS_GEEXTRUDEERD_POLYSTYREEN: 'XPS_GEEXTRUDEERD_POLYSTYREEN',
        PF_FENOLSCHUIM: 'PF_FENOLSCHUIM',
        PERLIET: 'PERLIET',
        GEEXPANDEERD_VERMICULIET: 'GEEXPANDEERD_VERMICULIET',
        CELLULOSE: 'CELLULOSE',
        PLANTAARDIGE_OF_DIERLIJKE_MATERIALEN_NIET_CELLULOSE: 'PLANTAARDIGE_OF_DIERLIJKE_MATERIALEN_NIET_CELLULOSE',
        HOUTWOLPLATEN: 'HOUTWOLPLATEN',
        KURK: 'KURK',
        UREUMFORMALDEHYDESCHUIM: 'UREUMFORMALDEHYDESCHUIM',
        GEEXPANDEERDE_KLEIKORRELS: 'GEEXPANDEERDE_KLEIKORRELS',
        GEEXTRUDEERD_POLYETHYLEEN: 'GEEXTRUDEERD_POLYETHYLEEN',
        CELLULAIR_GLAS: 'CELLULAIR_GLAS',
        DUNNE_REFLECTERENDE_LAAG: 'DUNNE_REFLECTERENDE_LAAG',
      },
      luchtlaagIsolatiemateriaal: {
        ONBEKEND: 'ONBEKEND',
        ZONDER_LUCHTLAAG: 'ZONDER_LUCHTLAAG',
        MET_LUCHTLAAG: 'MET_LUCHTLAAG',
        MET_LUCHTLAAG_AAN_WEERSZIJDEN: 'MET_LUCHTLAAG_AAN_WEERSZIJDEN',
      },
      configuratieOpslagvatWarmtewisselaar: {
        APART_OPSLAGVAT: 'APART_OPSLAGVAT',
        EXTERNE_WARMTEWISSELAAR: 'EXTERNE_WARMTEWISSELAAR',
        GEINTEGREERD_OPSLAGVAT: 'GEINTEGREERD_OPSLAGVAT',
        GEINTEGREERDE_WARMTEWISSELAAR: 'GEINTEGREERDE_WARMTEWISSELAAR',
        ONBEKEND: 'ONBEKEND',
      },
      warmteopslag: {
        JA: 'JA',
        NEEN: 'NEEN',
        ONBEKEND: 'ONBEKEND',
      },
      aanwezigheidIsolatie: {
        AANWEZIG: 'AANWEZIG',
        AFWEZIG: 'AFWEZIG',
        ONBEKEND: 'ONBEKEND',
      },
      opengaandeDelen: {
        MINDERHEID: 'MINDERHEID',
        MEERDERHEID: 'MEERDERHEID',
        GEEN: 'GEEN',
      },
      installaties: {
        ruimteverwarmingType: {
          CENTRAAL: 'CENTRAAL',
          DECENTRAAL: 'DECENTRAAL',
          ONVOLLEDIG: 'ONVOLLEDIG',
        },
        koelingType: {
          CENTRAAL: 'CENTRAAL',
        },
      },
      installatieType: {
        RUIMTEVERWARMING: 'RUIMTEVERWARMING',
        SANITAIR_WARM_WATER: 'SANITAIR_WARM_WATER',
        VENTILATIE: 'VENTILATIE',
        KOELING: 'KOELING',
        VERLICHTING: 'VERLICHTING',
      },
      soortTappunten: {
        DOUCHE_OF_BAD: 'DOUCHE_OF_BAD',
        KEUKEN: 'KEUKEN',
        ANDERE: 'ANDERE_TAPPUNTEN',
      },
      circulatieLeiding: {
        AANWEZIG: 'AANWEZIG',
        AFWEZIG: 'AFWEZIG',
        AANWEZIGHEID_ONBEKEND: 'Aanwezigheid onbekend',
      },
      combilusAanwezigheid: {
        AANWEZIG: 'AANWEZIG',
        AFWEZIG: 'AFWEZIG',
        AANWEZIGHEID_ONBEKEND: 'Aanwezigheid onbekend',
      },
      soortRegelingVentilatoren: {
        INLAATKLEPVERSTELLING: 'INLAATKLEPVERSTELLING',
        WAAIERSCHOEPVERSTELLING: 'WAAIERSCHOEPVERSTELLING',
        TOERENREGELING: 'TOERENREGELING',
        SMOORREGELING: 'SMOORREGELING',
        ONBEKEND: 'ONBEKEND',
        GEEN: 'GEEN',
      },
      ventilatieType: {
        MECHANISCHE_TOE_EN_AFVOERVENTILATIE: 'MECHANISCHE_TOE_EN_AFVOERVENTILATIE',
        MECHANISCHE_AFVOERVENTILATIE: 'MECHANISCHE_AFVOERVENTILATIE',
        MECHANISCHE_TOEVOERVENTILATIE: 'MECHANISCHE_TOEVOERVENTILATIE',
        NATUURLIJKE_VENTILATIE: 'NATUURLIJKE_VENTILATIE',
      },
      warmteterugwinningType: {
        GLYCOLBATTERIJ: 'GLYCOLBATTERIJ',
        KRUISSTROOMWARMTEWISSELAAR: 'KRUISSTROOMWARMTEWISSELAAR',
        DUBBELE_KRUISSTROOMWARMTEWISSELAAR: 'DUBBELE_KRUISSTROOMWARMTEWISSELAAR',
        WARMTEWIEL: 'WARMTEWIEL',
        TEGENSTROOMWARMTEWISSELAAR: 'TEGENSTROOMWARMTEWISSELAAR',
        ANDERE_OF_TYPE_ONBEKEND: 'ANDERE_OF_TYPE_ONBEKEND',
        GEEN: 'GEEN',
      },
      bestemming: {
        KANTOOR: 'KANTOOR',
        ANDERE: 'ANDERE',
        BIBLIOTHEEK: 'BIBLIOTHEEK',
        BRANDWEERKAZERNE: 'BRANDWEERKAZERNE',
        CINEMA: 'CINEMA',
        CULTUUR_OVERIGE: 'CULTUUR_OVERIGE',
        EVENEMENTENHAL: 'EVENEMENTENHAL',
        GERECHTSHOF: 'GERECHTSHOF',
        GEVANGENIS: 'GEVANGENIS',
        GEZONDHEIDSZORG_OVERIGE: 'GEZONDHEIDSZORG_OVERIGE',
        HANDELSZAAK: 'HANDELSZAAK',
        HOGER_OF_UNIVERSITAIR: 'HOGER_OF_UNIVERSITAIR',
        HOTEL: 'HOTEL',
        JEUGDBEWEGING: 'JEUGDBEWEGING',
        KINDERDAGVERBLIJF: 'KINDERDAGVERBLIJF',
        KLEUTER_LAGER: 'KLEUTER_LAGER',
        LUCHTHAVEN: 'LUCHTHAVEN',
        MAATSCHAP_DIENST_OVERIGE: 'MAATSCHAP_DIENST_OVERIGE',
        MEDISCHE_GROEPSPRAKTIJK: 'MEDISCHE_GROEPSPRAKTIJK',
        MUSEUM: 'MUSEUM',
        OPENBAAR_OVERIGE: 'OPENBAAR_OVERIGE',
        PODIUMKUNSTEN: 'PODIUMKUNSTEN',
        POLITIEKANTOOR: 'POLITIEKANTOOR',
        POSTKANTOOR: 'POSTKANTOOR',
        GEMEENTEHUIS: 'GEMEENTEHUIS',
        RESTAURANT_CAFE: 'RESTAURANT_CAFE',
        SECUNDAIR: 'SECUNDAIR',
        SPORT_OVERIGE: 'SPORT_OVERIGE',
        SPORTHAL: 'SPORTHAL',
        STATIONSGEBOUW: 'STATIONSGEBOUW',
        WIJKHUIS: 'WIJKHUIS',
        WOONZORGCENTRUM: 'WOONZORGCENTRUM',
        ZIEKENHUIS: 'ZIEKENHUIS',
        ZWEMBAD: 'ZWEMBAD',
      },
      functieType: {
        KEUKEN: 'KEUKEN',
        SERVERROOM: 'SERVERROOM',
        KOELRUIMTE: 'KOELRUIMTE',
        SPORTHAL: 'SPORTHAL',
        ZWEMBAD: 'ZWEMBAD',
        LABORATORIA: 'LABORATORIA',
        WERKPLAATS: 'WERKPLAATS',
        SAUNA: 'SAUNA',
        ANDERE: 'ANDERE',
      },
      compressiekoelmachineType: {
        LUCHTGEKOELDE_KLIMAATREGELAAR: 'LUCHTGEKOELDE_KLIMAATREGELAAR',
        LUCHTGEKOELD_MULTI_SPLIT: 'LUCHTGEKOELD_MULTI_SPLIT',
        WATERGEKOELDE_KLIMAATREGELAAR: 'WATERGEKOELDE_KLIMAATREGELAAR',
        WATERGEKOELD_MULTI_SPLIT: 'WATERGEKOELD_MULTI_SPLIT',
        WARMTEPOMP_LUCHT_WATER: 'WARMTEPOMP_LUCHT_WATER',
        LUCHTGEKOELDE_KOELGROEP: 'LUCHTGEKOELDE_KOELGROEP',
        WARMTEPOMP_WATER_WATER: 'WARMTEPOMP_WATER_WATER',
        WATERGEKOELDE_LUCHTGROEP: 'WATERGEKOELDE_LUCHTGROEP',
        ONBEKEND: 'ONBEKEND',
      },
      koelmiddel: {
        R22: 'R22',
        R134A: 'R134A',
        R404A: 'R404A',
        R407C: 'R407C',
        R410A: 'R410A',
        R32: 'R32',
        ANDERE: 'ANDERE',
      },
      errorErnst: {
        ERROR: 'ERROR',
        WARNING: 'WARNING',
      },
      energieEfficientieklasse: {
        A_PLUS_3: 'A_PLUS_3',
        A_PLUS_2: 'A_PLUS_2',
        A_PLUS_1: 'A_PLUS_1',
        A: 'A',
        B: 'B',
        C: 'C',
        D: 'D',
        E: 'E',
        F: 'F',
        G: 'G',
      },
      aanbeveling: {
        T0: 'T0',
        T1: 'T1',
        T2_GEVEL: 'T2_GEVEL',
        T2_PLAFOND: 'T2_PLAFOND',
        T2_PLAT_DAK: 'T2_PLAT_DAK',
        T2_HELLEND_DAK: 'T2_HELLEND_DAK',
        T2_VLOER: 'T2_VLOER',
        T2_VENSTERS: 'T2_VENSTERS',
        T2_DAKVENSTERS: 'T2_DAKVENSTERS',
        T2_LICHTEGEVELS: 'T2_LICHTEGEVELS',
        T2_POORTEN: 'T2_POORTEN',
        T3_GEVEL: 'T3_GEVEL',
        T3_PLAFOND: 'T3_PLAFOND',
        T3_PLAT_DAK: 'T3_PLAT_DAK',
        T3_HELLEND_DAK: 'T3_HELLEND_DAK',
        T3_VLOER: 'T3_VLOER',
        T3_VENSTERS: 'T3_VENSTERS',
        T3_DAKVENSTERS: 'T3_DAKVENSTERS',
        T3_LICHTEGEVELS: 'T3_LICHTEGEVELS',
        T3_POORTEN: 'T3_POORTEN',
        T4_GEVEL: 'T4_GEVEL',
        T4_PLAFOND: 'T4_PLAFOND',
        T4_PLAT_DAK: 'T4_PLAT_DAK',
        T4_HELLEND_DAK: 'T4_HELLEND_DAK',
        T4_VLOER: 'T4_VLOER',
        T4_VENSTERS: 'T4_VENSTERS',
        T4_DAKVENSTERS: 'T4_DAKVENSTERS',
        T4_LICHTEGEVELS: 'T4_LICHTEGEVELS',
        T4_POORTEN: 'T4_POORTEN',
        T5: 'T5',
        T6: 'T6',
        T7: 'T7',
        T8: 'T8',
        T9: 'T9',
        T10: 'T10',
        T11: 'T11',
        T23: 'T23',
        T12: 'T12',
        T13: 'T13',
        T14: 'T14',
        T15: 'T15',
        T16: 'T16',
        T22: 'T22',
        T17: 'T17',
        T18: 'T18',
        T19: 'T19',
        T20: 'T20',
        T21: 'T21',
        T24: 'T24',

        H1: 'H1',
        H2: 'H2',
        H3: 'H3',
        H4: 'H4',
        H5: 'H5',
        H6: 'H6',
        H7: 'H7',
        H8: 'H8',
      },
      percentage: {
        PERCENTAGE_ONBEKEND: 'Percentage onbekend',
        HONDERD: {
          code: 'HONDERD',
          omschrijving: '100%',
        },
      },
      requestPageBerekenOrigin: {
        opdracht: 'OPDRACHT',
        gebouweenheid: 'GEBOUWEENHEID',
      },
      certificaatStatusOptions: {
        geldig: 'Geldig',
        ingetrokken: 'Ingetrokken',
        geannuleerd: 'Geannuleerd',
        vervangen: 'Vervangen',
      },
      restwarmterecuperatieType: {
        RESTWARMTERECUPERATIE: 'DIRECTE_RESTWARMTERECUPERATIE',
        RESTWARMTERECUPERATIE_MET_WARMTEPOMP: 'RESTWARMTERECUPERATIE_MET_WARMTEPOMP',
      },
    };
  }
}

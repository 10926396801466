




























import { Component, Prop, Vue } from 'vue-property-decorator';
import { MenuItemInterface } from '@/domain/interface/intern/MenuItemInterface';

@Component
export default class MenuButton extends Vue {
  @Prop() readonly items: MenuItemInterface[] | undefined;
  @Prop() readonly buttonAttrs: object | undefined;

  clickItem(item: MenuItemInterface) {
    this.$emit('click-item', item);
  }
}

import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import VueI18n from 'vue-i18n';
import LocaleMessage = VueI18n.LocaleMessage;

export interface WarningMessage {
  warning: string | LocaleMessage;
}

@Module({ namespaced: true })
class WarningAlertMessages extends VuexModule {
  public warnings: WarningMessage[] = [];

  @Mutation
  public setWarnings(warnings: WarningMessage[]): void {
    this.warnings = warnings;
  }

  @Action
  public updateWarnings(warnings: WarningMessage[]) {
    this.context.commit('setWarnings', warnings);
  }

  @Action
  public resetWarnings() {
    this.context.commit('setWarnings', []);
  }

  get getWarnings() {
    return this.warnings;
  }
}

export default WarningAlertMessages;




































































import { Component, Vue } from 'vue-property-decorator';
import VlaanderenLogo from '@/components/layout/VlaanderenLogo.vue';
import FooterLink from '@/components/links/FooterLink.vue';

@Component({
  components: { FooterLink, VlaanderenLogo },
})
export default class Footer extends Vue {}

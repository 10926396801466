
















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Pagination extends Vue {
  @Prop() value!: number;
  @Prop({ default: false }) readonly showAlways!: boolean;
  @Prop({ default: 0 }) readonly totalElementCount!: number;
  @Prop({ default: 6 }) readonly elementsPerPage!: number;

  get pages() {
    return Math.floor((this.totalElementCount - 1) / this.elementsPerPage) + 1;
  }
}

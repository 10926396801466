














import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import TextInput from '@/components/form/TextInput.vue';

@Component({
  components: { TextInput },
})
export default class SearchInput extends Vue {
  @Prop(String) value!: string;
  @Prop({ default: 'zoeken-input' }) id!: string;
  @Prop(String) label: string | undefined;
  lastSearchValue = this.value;

  search() {
    //enkel opnieuw zoeken uitvoeren indien de search value veranderd is.
    if (this.lastSearchValue !== this.value) {
      this.lastSearchValue = this.value;
      this.$emit('search', this.value);
    }
  }

  resetSearch() {
    this.$emit('input', '');
    this.$nextTick(this.search);
  }
}

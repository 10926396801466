






































import { Component, Prop, Provide } from 'vue-property-decorator';
import { LocaleMessage } from 'vue-i18n';
import { mixins } from 'vue-class-component';
import TextInputMixin from '@/mixins/inputMixins/subMixins/TextInputMixin';

@Component
export default class DatePicker extends mixins(TextInputMixin) {
  @Prop(Function) readonly allowed: boolean | undefined;
  @Prop(String) readonly id: string | undefined;
  @Prop(String) readonly value: string | undefined;
  @Prop(String) readonly label: string | LocaleMessage | undefined;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop({ default: 40 }) readonly nudge: string | LocaleMessage | undefined;
  @Prop({ default: true }) readonly offsetY: string | LocaleMessage | undefined;
  @Prop({ default: false }) readonly clearable: string | LocaleMessage | undefined;
  @Prop({ default: Array }) readonly rules!: ((v: string) => string | boolean | LocaleMessage)[];

  @Provide() menu = false;

  get formattedDate() {
    if (!this.value) return null;

    const [year, month, day] = this.value.split('-');
    return `${day}/${month}/${year}`;
  }
}

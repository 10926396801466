import { ROL_DESK_D, ROL_STUDENT_TYPED } from '@/util/constants/constants';

export default class ConfigHelper {
  public static isLeeromgeving(): boolean {
    return process.env.VUE_APP_LEEROMGEVING === 'true';
  }

  public static isRolDeskundigeD(): boolean {
    return process.env.VUE_APP_ROLLEN_ACCESS === ROL_DESK_D;
  }

  public static isRolStudentTypeD(): boolean {
    return process.env.VUE_APP_ROLLEN_ACCESS === ROL_STUDENT_TYPED;
  }

  public static getBasisRegistersUrl(): string {
    return process.env.VUE_APP_BASE_URL_BASISREGISTERS_VLAANDEREN;
  }
}

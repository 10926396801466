

















import { Component, Prop, Vue } from 'vue-property-decorator';
import IconButton from '@/components/button/IconButton.vue';
import TextButton from '@/components/button/TextButton.vue';

@Component({
  components: { TextButton, IconButton },
})
export default class DialogCard extends Vue {
  @Prop(String) readonly title: string | undefined;
  @Prop(Array) readonly content: string[] | undefined;

  close() {
    this.$emit('close');
  }
}

import HttpService from '@/services/HttpService';
import {EnumInterface} from '@/domain/interface/intern/enumvalues/EnumInterface';

export default class GebruikerService {
    constructor(public http: HttpService) {
    }

    getGebruikers(): Promise<EnumInterface[]> {
        return this.http.get<EnumInterface[]>('gebruikers')
    }
}
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '@/views/Home.vue';
import store from '@/store/index.ts';
import { OpdrachtClass } from '@/domain/interface/opdracht/OpdrachtClass';
import { GebouweenheidClass } from '@/domain/interface/GebouweenheidClass';
import AuthService from '@/services/AuthorizationService';
import { EnergiestroomInterface } from '@/domain/interface/energiestroom/EnergiestroomInterface';
import { OpwekkerClass } from '@/domain/interface/opwekker/base-classes/OpwekkerClass';
import RuimteclusterRuimteverwarmingClass from '@/domain/interface/installaties/ruimteverwarming/ruimtecluster/RuimteclusterRuimteverwarmingClass';
import { RuimteverwarmingClass } from '@/domain/interface/installaties/ruimteverwarming/RuimteverwarmingClass';
import { Schildeel } from '@/domain/interface/schildeel/Schildeel';
import { SanitairWarmWaterClass } from '@/domain/interface/installaties/sanitairwarmwater/SanitairWarmWaterClass';
import { InstallatieVentilatieClass } from '@/domain/interface/installaties/ventilatie/InstallatieVentilatieClass';
import { BevochtigingClass } from '@/domain/interface/installaties/bevochtiging/BevochtigingClass';
import RuimteclusterVentilatieClass from '@/domain/interface/installaties/ventilatie/ruimtecluster/RuimteclusterVentilatieClass';
import RuimteclusterKoelingClass from '@/domain/interface/installaties/koeling/ruimtecluster/RuimteclusterKoelingClass';
import { KoelingClass } from '@/domain/interface/installaties/koeling/KoelingClass';
import { VerlichtingClass } from '@/domain/interface/installaties/verlichting/VerlichtingClass';
import ConfigHelper from '@/util/ConfigHelper';

const auth = new AuthService();

Vue.use(VueRouter);
const opdrachtUrl = '/opdracht/:opdrachtUuid';
const certificaatUrl = '/certificaat/:certificaatUuid';
const hernieuwbareEnergieMeldingenUrl = opdrachtUrl + '/meldingen';
const gebouwUrl = opdrachtUrl + '/gebouw/:gebouwUuid';
const gebouweenheidUrl = opdrachtUrl + '/gebouweenheid/:gebouweenheidUuid';

const geometrieAlgemeenUrl =
  gebouweenheidUrl + '/geometrie-algemeen/:geometrieAlgemeenUuid';
const geometrieDakenUrl =
  gebouweenheidUrl + '/geometrie-daken/:geometrieDakenUuid';
const geometrieVloerenUrl =
  gebouweenheidUrl + '/geometrie-vloeren/:geometrieVloerenUuid';
const geometrieGevelsUrl =
  gebouweenheidUrl + '/geometrie-gevels/:geometrieGevelsUuid';
const schildeelUrl = gebouweenheidUrl + '/schildeel/:schildeelUuid';
const gebouweenheidMeldingenUrl = gebouweenheidUrl + '/meldingen';
const gebouweenheidFeedbackEnAanbevelingenUrl = gebouweenheidUrl + '/feedback';

const ruimteverwarmingUrl =
  gebouweenheidUrl + '/ruimteverwarming/:ruimteverwarmingUuid';
const koelingUrl = gebouweenheidUrl + '/koeling/:koelingUuid';
const sanitairWarmWaterUrl =
  gebouweenheidUrl + '/sanitair-warm-water/:sanitairWarmWaterUuid';
const verlichtingUrl = gebouweenheidUrl + '/verlichting/:verlichtingUuid';
const ventilatieUrl = gebouweenheidUrl + '/ventilaties/:ventilatieUuid';
const bevochtigingUrl = gebouweenheidUrl + '/bevochtiging/:bevochtigingUuid';

const ruimteclusterVentilatieUrl =
  gebouweenheidUrl + '/ruimteclusters-ventilatie/:ruimteclusterVentilatieUuid';

const ruimteclusterRuimteverwarmingUrl =
  gebouweenheidUrl + '/ruimteclusters-ruimteverwarming/:ruimteclusterUuid';

const ruimteclusterKoelingUrl =
  gebouweenheidUrl + '/ruimteclusters-koeling/:ruimteclusterUuid';

const energiestroomUrl = opdrachtUrl + '/energiestroom/:energiestroomUuid';
const meterSuffix = '/meter/:meterUuid';
const opwekkerUrl = opdrachtUrl + '/opwekker/:opwekkerUuid';

const editAppendix = '/bewerk';
const addAppendix = '/aanmaken';
const duplicateAppendix = '/dupliceren';

const routes: Array<RouteConfig> = [
  {
    //HOME
    path: '//',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    meta: { breadcrumb: 'Start' },
    //HOME/Children
    children: [
      {
        name: 'start',
        path: '',
        component: Home,
      },
      {
        path: '/certificaten',
        name: 'certificaten',
        component: () => import('@/views/certificaten/CertificatenPage.vue'),
        meta: { breadcrumb: 'Certificaten' },
        beforeEnter: (to, from, next) => {
          return ConfigHelper.isLeeromgeving() ? false : next();
        },
      },
      {
        path: certificaatUrl + editAppendix,
        name: 'certificaatBewerken',
        component: () => import('@/views/certificaten/CertificaatBewerken.vue'),
        meta: { breadcrumb: 'Status certificaat aanpassen' },
      },
      {
        path: '/opdracht' + addAppendix,
        name: 'opdrachtAanmaken',
        component: () => import('@/views/opdracht/OpdrachtToevoegen.vue'),
        meta: { breadcrumb: 'Opdracht aanmaken' },
      },
      {
        path: opdrachtUrl + editAppendix,
        name: 'opdrachtBewerken',
        component: () => import('@/views/opdracht/OpdrachtBewerken.vue'),
        meta: { breadcrumb: 'Opdracht aanpassen' },
      },
      {
        path: opdrachtUrl + duplicateAppendix,
        name: 'opdrachtDupliceren',
        component: () => import('@/views/opdracht/OpdrachtDupliceren.vue'),
        meta: { breadcrumb: 'Opdracht dupliceren' },
      },
      {
        path: opdrachtUrl,
        component: {
          render(c) {
            return c('router-view');
          },
        },
        meta: {
          breadcrumb: 'Opdracht laden...',
          bcLinkText: (opdracht: OpdrachtClass) => opdracht.naam,
          bcBaseUrl: 'opdrachten/',
          bcUrlId: 'opdrachtUuid',
        },
        //HOME/Opdracht/Children
        children: [
          {
            name: 'opdracht',
            path: '',
            component: () => import('@/views/opdracht/OpdrachtLandingPage.vue'),
            beforeEnter(to, from, next) {
              if (from.name && to.meta) {
                to.meta.expandGebouwen = false;
                to.meta.expandOpwekkers = false;
                to.meta.expandEnergiestromen = false;
                if (from.path.includes('gebouweenheid')) {
                  to.meta.expandGebouwen = true;
                } else if (from.path.includes('opwekker')) {
                  to.meta.expandOpwekkers = true;
                } else if (from.path.includes('energiestroom')) {
                  to.meta.expandEnergiestromen = true;
                }
              }
              next();
            },
          },
          {
            path: hernieuwbareEnergieMeldingenUrl,
            name: 'HernieuwbareEnergieMeldingen',
            component: () =>
              import('@/views/opdracht/HernieuwbareEnergieMeldingen.vue'),
            meta: {
              breadcrumb: 'Meldingen',
            },
          },
          {
            path: opdrachtUrl + '/gebouweenheid' + addAppendix,
            name: 'gebouweenheidToevoegen',
            component: () =>
              import('@/views/gebouweenheid/GebouweenheidToevoegen.vue'),
            props: true,
            meta: { breadcrumb: 'Gebouw en gebouweenheden' },
          },
          // Duplicate geometrie algemeen routes needed for correct breadcrumbs for gebouweenheid bestemming andere dan niet residentieel
          {
            path: gebouweenheidUrl + '/geometrie-algemeen' + addAppendix,
            name: 'geometrieAlgemeenAanmakenBestemmingAndereDanNr',
            component: () =>
              import(
                '@/views/gebouweenheid/kengetal/geometrie/algemeen/GeometrieAlgemeenToevoegen.vue'
              ),
            meta: {
              breadcrumb: 'Algemene gebouw- en gebruikskarakteristieken',
            },
          },
          {
            path: geometrieAlgemeenUrl,
            name: 'geometrieAlgemeenBestemmingAndereDanNr',
            component: () =>
              import(
                '@/views/gebouweenheid/kengetal/geometrie/algemeen/GeometrieAlgemeenLandingPage.vue'
              ),
            meta: {
              breadcrumb: 'Algemene gebouw- en gebruikskarakteristieken',
            },
          },
          {
            path: gebouweenheidUrl,
            component: {
              render(c) {
                return c('router-view');
              },
            },
            meta: {
              breadcrumb: 'Gebouweenheid',
              bcLinkText: (gebouweenheid: GebouweenheidClass) =>
                gebouweenheid.naam || 'Gebouweenheid',
              bcBaseUrl: 'gebouweenheden/',
              bcUrlId: 'gebouweenheidUuid',
            },
            //HOME/Opdracht/gebouweenheid/children
            children: [
              {
                name: 'gebouweenheid',
                path: '',
                component: () =>
                  import('@/views/gebouweenheid/GebouweenheidLandingPage.vue'),
              },
              {
                path: gebouweenheidUrl + '/ruimteverwarmingen',
                meta: {
                  breadcrumb: 'Ruimteverwarmingen',
                },
                component: {
                  render(c) {
                    return c('router-view');
                  },
                },
                children: [
                  {
                    name: 'ruimteverwarmingen',
                    path: '',
                    component: () =>
                      import(
                        '@/views/installatie/ruimteverwarmingOverzicht/RuimteverwarmingOverzicht.vue'
                      ),
                  },
                  {
                    name: 'ruimteverwarmingAanmaken',
                    meta: {
                      breadcrumb: 'Ruimteverwarming toevoegen',
                    },
                    path: gebouweenheidUrl + '/ruimteverwarming' + addAppendix,
                    component: () =>
                      import(
                        '@/views/installatie/ruimteverwarmingOverzicht/ruimteverwarming/RuimteverwarmingToevoegen.vue'
                      ),
                  },
                  {
                    name: 'ruimteverwarming',
                    meta: {
                      breadcrumb: 'Ruimteverwarming laden...',
                      bcLinkText: (ruimteverwarming: RuimteverwarmingClass) =>
                        ruimteverwarming.naam,
                      bcBaseUrl: 'ruimteverwarming/',
                      bcUrlId: 'ruimteverwarmingUuid',
                    },
                    path: ruimteverwarmingUrl,
                    component: () =>
                      import(
                        '@/views/installatie/ruimteverwarmingOverzicht/ruimteverwarming/RuimteverwarmingLandingPage.vue'
                      ),
                  },
                  {
                    name: 'ruimteclusterRuimteverwarming',
                    meta: {
                      breadcrumb: 'Ruimtecluster laden...',
                      bcLinkText: (
                        ruimteclusterRuimteverwarming: RuimteclusterRuimteverwarmingClass
                      ) => ruimteclusterRuimteverwarming.naam,
                      bcBaseUrl: 'ruimteclusters-ruimteverwarming/',
                      bcUrlId: 'ruimteclusterUuid',
                    },
                    path: ruimteclusterRuimteverwarmingUrl,
                    component: () =>
                      import(
                        '@/views/installatie/ruimteverwarmingOverzicht/ruimtecluster/RuimteclusterRuimteverwarmingLandingPage.vue'
                      ),
                  },
                  {
                    name: 'ruimteclusterRuimteverwarmingAanmaken',
                    meta: {
                      breadcrumb: 'Ruimtecluster toevoegen',
                    },
                    path:
                      gebouweenheidUrl +
                      '/ruimteclusters-ruimteverwarming' +
                      addAppendix,
                    component: () =>
                      import(
                        '@/views/installatie/ruimteverwarmingOverzicht/ruimtecluster/RuimteclusterRuimteverwarmingToevoegen.vue'
                      ),
                  },
                ],
              },
              {
                name: 'sanitairWarmWaterAanmaken',
                meta: {
                  breadcrumb: 'Sanitair Warm Water toevoegen',
                },
                path: gebouweenheidUrl + '/sanitairwarmwater' + addAppendix,
                component: () =>
                  import(
                    '@/views/installatie/sanitairWarmWater/SanitairWarmWaterToevoegen.vue'
                  ),
              },
              {
                name: 'sanitairWarmWaterBekijken',
                meta: {
                  breadcrumb: 'Sanitair warm water laden...',
                  bcLinkText: (sanitairWarmWater: SanitairWarmWaterClass) =>
                    sanitairWarmWater.naam,
                  bcBaseUrl: 'sanitair-warm-water/',
                  bcUrlId: 'sanitairWarmWaterUuid',
                },
                path: sanitairWarmWaterUrl,
                component: () =>
                  import(
                    '@/views/installatie/sanitairWarmWater/SanitairWarmWaterLandingPage.vue'
                  ),
              },
              {
                name: 'verlichtingAanmaken',
                meta: {
                  breadcrumb: 'Verlichting toevoegen',
                },
                path: gebouweenheidUrl + '/verlichting' + addAppendix,
                component: () =>
                  import(
                    '@/views/installatie/verlichting/VerlichtingToevoegen.vue'
                  ),
              },
              {
                name: 'verlichting',
                meta: {
                  breadcrumb: 'Verlichting laden...',
                  bcLinkText: (verlichting: VerlichtingClass) =>
                    verlichting.naam,
                  bcBaseUrl: 'verlichtingen/',
                  bcUrlId: 'verlichtingUuid',
                },
                path: verlichtingUrl,
                component: () =>
                  import(
                    '@/views/installatie/verlichting/VerlichtingLandingPage.vue'
                  ),
              },
              {
                path: gebouweenheidUrl + '/ventilatie-overzicht',
                meta: {
                  breadcrumb: 'Ventilaties',
                },
                component: {
                  render(c) {
                    return c('router-view');
                  },
                },
                children: [
                  {
                    name: 'ventilaties',
                    path: '',
                    component: () =>
                      import(
                        '@/views/installatie/ventilatieOverzicht/VentilatieOverzicht.vue'
                      ),
                  },
                  {
                    name: 'ventilatieAanmaken',
                    meta: {
                      breadcrumb: 'Ventilatie toevoegen',
                    },
                    path: gebouweenheidUrl + '/ventilaties' + addAppendix,
                    component: () =>
                      import(
                        '@/views/installatie/ventilatieOverzicht/ventilatie/VentilatieToevoegen.vue'
                      ),
                  },
                  {
                    name: 'ventilatie',
                    meta: {
                      breadcrumb: 'Ventilatie laden...',
                      bcLinkText: (ventilatie: InstallatieVentilatieClass) =>
                        ventilatie.naam,
                      bcBaseUrl: 'ventilaties/',
                      bcUrlId: 'ventilatieUuid',
                    },
                    path: ventilatieUrl,
                    component: () =>
                      import(
                        '@/views/installatie/ventilatieOverzicht/ventilatie/VentilatieLandingPage.vue'
                      ),
                  },
                  {
                    name: 'bevochtigingAanmaken',
                    meta: {
                      breadcrumb: 'Bevochtiging toevoegen',
                    },
                    path: gebouweenheidUrl + '/bevochtiging' + addAppendix,
                    component: () =>
                      import(
                        '@/views/installatie/ventilatieOverzicht/bevochtiging/BevochtigingToevoegen.vue'
                      ),
                  },
                  {
                    name: 'bevochtiging',
                    meta: {
                      breadcrumb: 'Bevochtiging laden...',
                      bcLinkText: (bevochtiging: BevochtigingClass) =>
                        bevochtiging.naam,
                      bcBaseUrl: 'bevochtigingen/',
                      bcUrlId: 'bevochtigingUuid',
                    },
                    path: bevochtigingUrl,
                    component: () =>
                      import(
                        '@/views/installatie/ventilatieOverzicht/bevochtiging/BevochtigingLandingPage.vue'
                      ),
                  },
                  {
                    name: 'ruimteclusterVentilatieAanmaken',
                    meta: {
                      breadcrumb: 'Ruimtecluster toevoegen',
                    },
                    path:
                      gebouweenheidUrl +
                      '/ruimteclusters-ventilatie' +
                      addAppendix,
                    component: () =>
                      import(
                        '@/views/installatie/ventilatieOverzicht/ruimtecluster/RuimteclusterVentilatieToevoegen.vue'
                      ),
                  },
                  {
                    name: 'ruimteclusterVentilatie',
                    meta: {
                      breadcrumb: 'Ruimtecluster ventilatie laden...',
                      bcLinkText: (
                        ruimteclusterVentilatie: RuimteclusterVentilatieClass
                      ) => ruimteclusterVentilatie.naam,
                      bcBaseUrl: 'ruimteclusters-ventilatie/',
                      bcUrlId: 'ruimteclusterVentilatieUuid',
                    },
                    path: ruimteclusterVentilatieUrl,
                    component: () =>
                      import(
                        '@/views/installatie/ventilatieOverzicht/ruimtecluster/RuimteclusterVentilatieLandingPage.vue'
                      ),
                  },
                ],
              },
              {
                path: gebouweenheidUrl + '/koelingen',
                meta: {
                  breadcrumb: 'Koelingen',
                },
                component: {
                  render(c) {
                    return c('router-view');
                  },
                },
                children: [
                  {
                    name: 'koelingen',
                    path: '',
                    component: () =>
                      import(
                        '@/views/installatie/koelingOverzicht/KoelingOverzicht.vue'
                      ),
                  },
                  {
                    name: 'koelingAanmaken',
                    path: gebouweenheidUrl + '/koelingen' + addAppendix,
                    meta: {
                      breadcrumb: 'Koeling aanmaken',
                    },
                    component: () =>
                      import(
                        '@/views/installatie/koelingOverzicht/koeling/KoelingToevoegen.vue'
                      ),
                  },
                  {
                    name: 'koeling',
                    meta: {
                      breadcrumb: 'Koeling laden...',
                      bcLinkText: (koeling: KoelingClass) => koeling.naam,
                      bcBaseUrl: 'koelingen/',
                      bcUrlId: 'koelingUuid',
                    },
                    path: koelingUrl,
                    component: () =>
                      import(
                        '@/views/installatie/koelingOverzicht/koeling/KoelingLandingPage.vue'
                      ),
                  },
                  {
                    name: 'ruimteclusterKoeling',
                    meta: {
                      breadcrumb: 'Ruimtecluster laden...',
                      bcLinkText: (
                        ruimteclusterKoeling: RuimteclusterKoelingClass
                      ) => ruimteclusterKoeling.naam,
                      bcBaseUrl: 'ruimteclusters-koeling/',
                      bcUrlId: 'ruimteclusterUuid',
                    },
                    path: ruimteclusterKoelingUrl,
                    component: () =>
                      import(
                        '@/views/installatie/koelingOverzicht/ruimtecluster/RuimteclusterKoelingLandingPage.vue'
                      ),
                  },
                  {
                    name: 'ruimteclusterKoelingAanmaken',
                    meta: {
                      breadcrumb: 'Ruimtecluster toevoegen',
                    },
                    path:
                      gebouweenheidUrl +
                      '/ruimteclusters-koeling' +
                      addAppendix,
                    component: () =>
                      import(
                        '@/views/installatie/koelingOverzicht/ruimtecluster/RuimteclusterKoelingToevoegen.vue'
                      ),
                  },
                ],
              },
              {
                path: gebouweenheidUrl + '/geometrie-algemeen' + addAppendix,
                name: 'geometrieAlgemeenAanmaken',
                component: () =>
                  import(
                    '@/views/gebouweenheid/kengetal/geometrie/algemeen/GeometrieAlgemeenToevoegen.vue'
                  ),
                meta: {
                  breadcrumb: 'Algemene gebouw- en gebruikskarakteristieken',
                },
              },
              {
                path: geometrieAlgemeenUrl,
                name: 'geometrieAlgemeen',
                component: () =>
                  import(
                    '@/views/gebouweenheid/kengetal/geometrie/algemeen/GeometrieAlgemeenLandingPage.vue'
                  ),
                meta: {
                  breadcrumb: 'Algemene gebouw- en gebruikskarakteristieken',
                },
              },
              {
                path: gebouweenheidUrl + '/geometrie-vloeren' + addAppendix,
                name: 'geometrieVloerenAanmaken',
                component: () =>
                  import(
                    '@/views/gebouweenheid/kengetal/geometrie/vloeren/GeometrieVloerenToevoegen.vue'
                  ),
                meta: {
                  breadcrumb: 'Geometrie vloeren',
                },
              },
              {
                path: geometrieVloerenUrl,
                name: 'geometrieVloeren',
                component: () =>
                  import(
                    '@/views/gebouweenheid/kengetal/geometrie/vloeren/GeometrieVloerenLandingPage.vue'
                  ),
                meta: {
                  breadcrumb: 'Geometrie vloeren',
                },
              },
              {
                path: gebouweenheidUrl + '/geometrie-daken' + addAppendix,
                name: 'geometrieDakenAanmaken',
                component: () =>
                  import(
                    '@/views/gebouweenheid/kengetal/geometrie/daken/GeometrieDakenToevoegen.vue'
                  ),
                meta: {
                  breadcrumb: 'Geometrie daken',
                },
              },
              {
                path: geometrieDakenUrl,
                name: 'geometrieDaken',
                component: () =>
                  import(
                    '@/views/gebouweenheid/kengetal/geometrie/daken/GeometrieDakenLandingPage.vue'
                  ),
                meta: {
                  breadcrumb: 'Geometrie daken',
                },
              },
              {
                path: gebouweenheidUrl + '/geometrie-gevels' + addAppendix,
                name: 'geometrieGevelsAanmaken',
                component: () =>
                  import(
                    '@/views/gebouweenheid/kengetal/geometrie/gevels/GeometrieGevelsToevoegen.vue'
                  ),
                meta: {
                  breadcrumb: 'Geometrie gevels',
                },
              },
              {
                path: geometrieGevelsUrl,
                name: 'geometrieGevels',
                component: () =>
                  import(
                    '@/views/gebouweenheid/kengetal/geometrie/gevels/GeometrieGevelsLandingPage.vue'
                  ),
                meta: {
                  breadcrumb: 'Geometrie gevels',
                },
              },
              {
                path: schildeelUrl,
                component: {
                  render(c) {
                    return c('router-view');
                  },
                },
                meta: {
                  breadcrumb: 'Schildeel opbouw laden...',
                  bcLinkText: (schildeel: Schildeel) =>
                    new Schildeel(schildeel).getTitle(),
                  bcBaseUrl: 'schildelen/',
                  bcUrlId: 'schildeelUuid',
                },
                //HOME/Opdracht/gebouweenheid/schildeel/children
                children: [
                  {
                    path: schildeelUrl + '/schildeel-opbouw' + addAppendix,
                    name: 'schildeelOpbouwToevoegen',
                    component: () =>
                      import(
                        '@/views/gebouweenheid/kengetal/schildeelopbouw/SchildeelOpbouwToevoegen.vue'
                      ),
                    props: true,
                  },
                  {
                    path:
                      schildeelUrl + '/schildeel-opbouw/:schildeelOpbouwUuid',
                    name: 'schildeelOpbouw',
                    component: () =>
                      import(
                        '@/views/gebouweenheid/kengetal/schildeelopbouw/SchildeelOpbouwLandingPage.vue'
                      ),
                    props: true,
                  },
                ],
              },
              {
                path: gebouweenheidMeldingenUrl,
                name: 'GebouweenheidMeldingen',
                component: () =>
                  import(
                    '@/views/gebouweenheid/kengetal/gebouweenheidCalculation/GebouweenheidMeldingen.vue'
                  ),
                meta: {
                  breadcrumb: 'Meldingen',
                },
              },
              {
                path: gebouweenheidFeedbackEnAanbevelingenUrl,
                name: 'FeedbackEnAanbevelingenLandingPage',
                component: () =>
                  import(
                    '@/views/gebouweenheid/kengetal/gebouweenheidCalculation/FeedbackEnAanbevelingenLandingPage.vue'
                  ),
                props: true,
                meta: {
                  breadcrumb: 'Feedback en aanbevelingen',
                },
              },
            ],
          },
          {
            path: opdrachtUrl + '/metingen',
            name: 'metingen',
            component: () => import('@/views/meting/MetingLandingPage.vue'),
            props: true,
            meta: { breadcrumb: 'Metingen toevoegen' },
          },
          {
            path: gebouwUrl,
            component: {
              render(c) {
                return c('router-view');
              },
            },
            children: [
              {
                path: gebouwUrl + editAppendix,
                name: 'gebouwLandingPage',
                component: () => import('@/views/gebouw/GebouwLandingPage.vue'),
                meta: { breadcrumb: 'Gebouw en gebouweenheden' },
              },
            ],
          },
          {
            path:
              opdrachtUrl +
              '/energiestroom' +
              addAppendix +
              '/:energiestroomType',
            name: 'energiestroomAanmaken',
            meta: { breadcrumb: 'Energiestroom toevoegen' },
            component: () =>
              import('@/views/energiestroom/EnergiestroomToevoegen.vue'),
          },
          {
            path: energiestroomUrl,
            meta: {
              breadcrumb: 'Energiestroom laden...',
              bcLinkText: (energiestroom: EnergiestroomInterface) =>
                energiestroom.naam,
              bcBaseUrl: 'energiestromen/',
              bcUrlId: 'energiestroomUuid',
            },
            component: {
              render(c) {
                return c('router-view');
              },
            },
            children: [
              {
                path: '',
                name: 'energiestroom',
                component: () =>
                  import('@/views/energiestroom/EnergiestroomLandingPage.vue'),
              },
              {
                path: energiestroomUrl + meterSuffix,
                name: 'energiestroom_meter',
                component: () =>
                  import('@/views/energiestroom/EnergiestroomLandingPage.vue'),
                beforeEnter(to, from, next) {
                  if (from.name && to.meta) {
                    to.meta.navTo = 'opdracht';
                    next();
                  }
                },
              },
            ],
          },
          {
            path: opdrachtUrl + '/opwekker',
            name: 'opwekkerAanmaken',
            meta: { breadcrumb: 'Opwekker toevoegen' },
            component: () => import('@/views/opwekker/OpwekkerToevoegen.vue'),
          },
          {
            path: opwekkerUrl,
            meta: {
              breadcrumb: 'Opwekker laden...',
              bcLinkText: (opwekker: OpwekkerClass) => opwekker.naam,
              bcBaseUrl: 'opwekkers/',
              bcUrlId: 'opwekkerUuid',
            },
            component: {
              render(c) {
                return c('router-view');
              },
            },
            children: [
              {
                path: '',
                name: 'opwekker',
                component: () =>
                  import('@/views/opwekker/OpwekkerLandingPage.vue'),
              },
              {
                path: opwekkerUrl + meterSuffix,
                name: 'opwekker_meter',
                component: () =>
                  import('@/views/opwekker/OpwekkerLandingPage.vue'),
                beforeEnter(to, from, next) {
                  if (from.name && to.meta) {
                    to.meta.navTo = 'opdracht';
                    next();
                  }
                },
              },
            ],
          },
        ],
      },
      {
        path: '/dev/icons',
        name: 'icons',
        component: () => import('@/views/dev/Icons.vue'),
        meta: { breadcrumb: 'Icons' },
      },
      {
        path: '/dev/inputs',
        name: 'inputs',
        component: () => import('@/views/dev/Inputs.vue'),
        meta: { breadcrumb: 'Inputs' },
      },
      {
        path: '/afmelden',
        name: 'afmelden',
        component: () => import('@/views/Afmelden.vue'),
      },
      {
        path: '/wisselen',
        name: 'wisselen',
        component: () => import('@/views/Wisselen.vue'),
      },
      {
        path: '*',
        name: 'notFound',
        component: () => import('@/views/NotFound.vue'),
        meta: { breadcrumb: 'Onbekende pagina' },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path !== '/' && process.env.NODE_ENV !== 'development') {
    auth.isLoggedIn().then((loggedIn) => {
      if (loggedIn) {
        next();
        store.dispatch('Errors/updateErrors', []);
      } else {
        console.log('Access denied --> redirect naar home: inloggen!');
        store.dispatch('Errors/updateErrors', [
          {
            message:
              'Uw sessie is verlopen, gelieve af te melden en opnieuw aan te melden',
          },
        ]);
        next('/');
      }
    });
  } else {
    next();
    store.dispatch('Errors/resetErrors');
  }
});

export default router;

/*
 *    +---------------------------------------+
 *    |                                       |
 *    |              ROUTES                   |
 *    |                                       |
 *    +---------------------------------------+
 *
 * Opbouw van routes:
 *
 * Wanneer een route children heeft: heeft de main component enkel een router-view. Deze main component bevat een breadcrumb.
 * De main pagina komt dan onder children met path ''. Deze main pagina zal een name hebben, maar geen breadcrumb.
 * De overige children hebben hun eigen path, name en breadcrumb.
 *
 * Voorbeeld:
 *   {
 *     path: '/x',
 *     component: {render(c) {return c('router-view')},
 *     meta: {breadcrumb: "Pagina x"},
 *     children: [
 *       {
 *         name: 'pageX',
 *         path: '',
 *         component: () => import('@/views/PageX.vue'),
 *       },
 *       {
 *         name: 'pageY',
 *         path: '/x/y',
 *         component: () => import('@/views/PageY.vue'),
 *         meta: {breadcrumb: "Pagina y"},
 *       },
 *     ]
 *    }
 *
 * ------------------------------------------------------------------------------------------------
 *    +---------------------------------------+
 *    |                                       |
 *    |            BREADCRUMBS                |
 *    |                                       |
 *    +---------------------------------------+
 *    Breadcrumbs kunnen dynamisch zijn (bijvoorbeeld de naam van de opdracht displayen ipv 'opdracht'.)
 *    Deze breadcrumbs worden als volgt opgebouwd.
 *
 *     meta: {
 *        breadcrumb: 'Opdrachtnaam laden...',
 *        bcLinkText: (opdracht: OpdrachtClass) => opdracht.naam,
 *        bcBaseUrl: 'opdrachten/',
 *        bcUrlId: 'opdrachtUuid',
 *      },
 *
 *    breadcrumb: Bevat de default breadcrumb. Deze wordt gebruikt indien er geen dynamische breadcrumb gegenereerd kan worden.
 *    bcLinkText: Bevat een functie: Wat willen we van het object terugkrijgen?
 *    bcBaseUrl:  Bevat de endpoint van de backend die aangeroepen moet worden om het benodigde object op te halen.
 *    bcUrlId:    Bevat de naam van de parameter die in de url gebruikt wordt.
 *
 * */




















import { Component, Vue, Watch } from 'vue-property-decorator';
import axios from 'axios';
import { RouteRecord } from 'vue-router';

class BreadCrumbClass {
  text: string;
  disabled: boolean;
  to: string;

  constructor(text: string, to: string) {
    this.text = text;
    this.disabled = false;
    this.to = to;
  }
}

@Component
export default class Breadcrumbs extends Vue {
  created() {
    this.refreshBreadcrumbs();
  }

  breadcrumbs: BreadCrumbClass[] = [];

  @Watch('$route.matched')
  refreshBreadcrumbs() {
    const breadcrumbCache = this.breadcrumbs;
    this.breadcrumbs = [];

    for (const routeRecord of this.$route.matched) {
      //only push the objects with a breadcrumb tag
      if (Breadcrumbs.hasBreadcrumb(routeRecord)) {
        const path = this.getPath(routeRecord);

        //Use the breadcrumb from cache if exists
        let breadcrumb: BreadCrumbClass | undefined = breadcrumbCache.find(
          (bc) => bc?.to === path
        );
        if (!breadcrumb) {
          breadcrumb = new BreadCrumbClass(routeRecord.meta.breadcrumb, path);
          if (Breadcrumbs.hasUrlToFetch(routeRecord)) {
            this.fetchAndReplaceText(routeRecord, breadcrumb);
          }
        }
        this.breadcrumbs.push(breadcrumb);
      }
    }

    this.disableLastBreadcrumb();
  }

  private disableLastBreadcrumb() {
    if (this.breadcrumbs.length > 0) {
      this.breadcrumbs.forEach((bc) => {
        bc.disabled = false;
      });
      this.breadcrumbs[this.breadcrumbs.length - 1].disabled = true;
    }
  }

  private fetchAndReplaceText(
    routeRecord: RouteRecord,
    breadcrumb: BreadCrumbClass
  ) {
    axios
      .get(
        routeRecord.meta.bcBaseUrl +
          this.$route.params[routeRecord.meta.bcUrlId]
      )
      .then((resp) => {
        const bc = this.breadcrumbs.find((b) => b.to === breadcrumb.to);
        if (bc) {
          bc.text = routeRecord.meta.bcLinkText(resp.data);
        }
      });
  }

  private static hasUrlToFetch(routeRecord: RouteRecord) {
    return (
      Object.hasOwnProperty.call(routeRecord.meta, 'bcBaseUrl') &&
      Object.hasOwnProperty.call(routeRecord.meta, 'bcUrlId')
    );
  }

  private static hasBreadcrumb(routeRecord: RouteRecord) {
    return (
      Object.hasOwnProperty.call(routeRecord, 'meta') &&
      Object.hasOwnProperty.call(routeRecord.meta, 'breadcrumb')
    );
  }

  private getPath(routeRecord: RouteRecord) {
    let path = routeRecord.path;
    //Change the parameters to go to the right previous pages.
    Object.keys(this.$route.params).forEach((key) => {
      path = path.replace(':' + key, this.$route.params[key]);
    });
    return path;
  }
}

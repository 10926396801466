import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

//import icons
import '@/assets/fonts/iconfont/icons.woff';

import IconAppartement from '@/components/icon/custom/IconAppartement.vue';
import IconAsterix from '@/components/icon/custom/IconAsterix.vue';
import IconBarometer from '@/components/icon/custom/IconBarometer.vue';
import IconBejaard from '@/components/icon/custom/IconBejaard.vue';
import IconBlockArrow from '@/components/icon/custom/IconBlockArrow.vue';
import IconCircle from '@/components/icon/custom/IconCircle.vue';
import IconDak from '@/components/icon/custom/IconDak.vue';
import IconDeur from '@/components/icon/custom/IconDeur.vue';
import IconDuim from '@/components/icon/custom/IconDuim.vue';
import IconGevel from '@/components/icon/custom/IconGevel.vue';
import IconGezondheid from '@/components/icon/custom/IconGezondheid.vue';
import IconInfrastructuur from '@/components/icon/custom/IconInfrastructuur.vue';
import IconKlimaat from '@/components/icon/custom/IconKlimaat.vue';
import IconKoeling from '@/components/icon/custom/IconKoeling.vue';
import IconLuchtdichtheid from '@/components/icon/custom/IconLuchtdichtheid.vue';
import IconMeerComfort from '@/components/icon/custom/IconMeerComfort.vue';
import IconMeerwaarde from '@/components/icon/custom/IconMeerwaarde.vue';
import IconMin from '@/components/icon/custom/IconMin.vue';
import IconPlus from '@/components/icon/custom/IconPlus.vue';
import IconPrijs from '@/components/icon/custom/IconPrijs.vue';
import IconRaam from '@/components/icon/custom/IconRaam.vue';
import IconSWW from '@/components/icon/custom/IconSWW.vue';
import IconTechnisch from '@/components/icon/custom/IconTechnisch.vue';
import IconUitroepteken from '@/components/icon/custom/IconUitroepteken.vue';
import IconVentilatie from '@/components/icon/custom/IconVentilatie.vue';
import IconVerlichting from '@/components/icon/custom/IconVerlichting.vue';
import IconVerlichtingKruis from '@/components/icon/custom/IconVerlichtingKruis.vue';
import IconVerlichtingVinkje from '@/components/icon/custom/IconVerlichtingVinkje.vue';
import IconVerwarming from '@/components/icon/custom/IconVerwarming.vue';
import IconVerwarmingKruis from '@/components/icon/custom/IconVerwarmingKruis.vue';
import IconVloeren from '@/components/icon/custom/IconVloeren.vue';
import IconZonneEnergie from '@/components/icon/custom/IconZonneEnergie.vue';
import IconZonneEnergieKruis from '@/components/icon/custom/IconZonneEnergieKruis.vue';
import IconZonneEnergieVinkje from '@/components/icon/custom/IconZonneEnergieVinkje.vue';
import IconVerwarmingVinkje from '@/components/icon/custom/IconVerwarmingVinkje.vue';
import IconPlafond from '@/components/icon/custom/IconPlafond.vue';
import IconSuperisolerend from '@/components/icon/custom/IconSuperisolerend.vue';
import IconDatumKalender from '@/components/icon/custom/IconDatumKalender.vue';
import IconWinter from '@/components/icon/custom/IconWinter.vue';
import IconLamp from '@/components/icon/custom/IconLamp.vue';
import IconGebruikersgedrag from '@/components/icon/custom/IconGebruikersgedrag.vue';
import IconZelfgebruikAlgemeen from '@/components/icon/custom/IconZelfgebruikAlgemeen.vue';
import IconGroeneStroom from '@/components/icon/custom/IconGroeneStroom.vue';
import IconGroeneWarmte from '@/components/icon/custom/IconGroeneWarmte.vue';
import IconHernieuwbareKoeling from '@/components/icon/custom/IconHernieuwbareKoeling.vue';

Vue.use(Vuetify);

export const preset = {
  theme: {
    themes: {
      light: {
        primary: '#105269',
        primaryAccent1: '#4B7284',
        primaryAccent2: '#728F9B',
        primaryAccent3: '#B7C9CD',
        secondary: '#38373A',
        secondaryAccent1: '#6B6C6E',
        secondaryAccent2: '#98999B',
        secondaryAccent3: '#C2C3C4',
        success: '#1A5924',
        successAccent1: '#4AA832',
        successAccent2: '#9FCB8A',
        error: '#C83C22',
        errorAccent1: '#E8754C',
        errorAccent2: '#EBA386',
        info: '#075E8D',
        infoAccent1: '#3F7A9C',
        infoAccent2: '#6098B6',

        white: '#fff',
      },
    },
  },
  icons: {
    values: {
      appartement: {
        component: IconAppartement,
      },
      asterix: {
        component: IconAsterix,
      },
      barometer: {
        component: IconBarometer,
      },
      bejaard: {
        component: IconBejaard,
      },
      'block-arrow': {
        component: IconBlockArrow,
      },
      circle: {
        component: IconCircle,
      },
      dak: {
        component: IconDak,
      },
      'datum-kalender': {
        component: IconDatumKalender,
      },
      deur: {
        component: IconDeur,
      },
      duim: {
        component: IconDuim,
      },
      gebruikersgedrag: {
        component: IconGebruikersgedrag,
      },
      gevel: {
        component: IconGevel,
      },
      gezondheid: {
        component: IconGezondheid,
      },
      'groene-stroom': {
        component: IconGroeneStroom,
      },
      'groene-warmte': {
        component: IconGroeneWarmte,
      },
      'hernieuwbare-koeling': {
        component: IconHernieuwbareKoeling,
      },
      infrastructuur: {
        component: IconInfrastructuur,
      },
      klimaat: {
        component: IconKlimaat,
      },
      koeling: {
        component: IconKoeling,
      },
      lamp: {
        component: IconLamp,
      },
      luchtdichtheid: {
        component: IconLuchtdichtheid,
      },
      'meer-comfort': {
        component: IconMeerComfort,
      },
      meerwaarde: {
        component: IconMeerwaarde,
      },
      min: {
        component: IconMin,
      },
      plafond: {
        component: IconPlafond,
      },
      plus: {
        component: IconPlus,
      },
      prijs: {
        component: IconPrijs,
      },
      raam: {
        component: IconRaam,
      },
      superisolerend: {
        component: IconSuperisolerend,
      },
      sww: {
        component: IconSWW,
      },
      technisch: {
        component: IconTechnisch,
      },
      uitroepteken: {
        component: IconUitroepteken,
      },
      ventilatie: {
        component: IconVentilatie,
      },
      verlichting: {
        component: IconVerlichting,
      },
      'verlichting-kruis': {
        component: IconVerlichtingKruis,
      },
      'verlichting-vinkje': {
        component: IconVerlichtingVinkje,
      },
      verwarming: {
        component: IconVerwarming,
      },
      'verwarming-kruis': {
        component: IconVerwarmingKruis,
      },
      'verwarming-vinkje': {
        component: IconVerwarmingVinkje,
      },
      vloeren: {
        component: IconVloeren,
      },
      winter: {
        component: IconWinter,
      },
      'zelfgebruik-algemeen': {
        component: IconZelfgebruikAlgemeen,
      },
      'zonne-energie': {
        component: IconZonneEnergie,
      },
      'zonne-energie-kruis': {
        component: IconZonneEnergieKruis,
      },
      'zonne-energie-vinkje': {
        component: IconZonneEnergieVinkje,
      },
    },
  },
};
export default new Vuetify(preset);

import { Locales } from './locales';
import nl from './nl.json';
import VueI18n from 'vue-i18n';
import Vue from 'vue';

const messages = {
  [Locales.NL]: nl,
};

Vue.use(VueI18n);

export const i18n = new VueI18n({
  messages,
  locale: Locales.NL,
  fallbackLocale: Locales.NL,
});

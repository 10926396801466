


















import { Component, Prop, Vue } from 'vue-property-decorator';
import WizardButtonGroup from '@/components/button/WizardButtonGroup.vue';
import TextButton from '@/components/button/TextButton.vue';
import axios from 'axios';
import { DeleteDialogObject } from '@/domain/interface/DeleteDialogObject';

@Component({
  components: {
    TextButton,
    WizardButtonGroup,
  },
})
export default class DeleteDialog extends Vue {
  @Prop(Boolean) value!: boolean;
  @Prop(String) title!: string;
  @Prop(String) content!: string;
  @Prop(String) deleteText!: string;
  @Prop(Object) readonly deleteObject!: DeleteDialogObject;

  get dialogTitle() {
    return `${this.$t(this.title)} ${this.deleteObject.naam}`;
  }

  get dialogContent() {
    return this.$t(this.content);
  }

  verwijder() {
    if (this.deleteObject.links.verwijderen) {
      axios
        .delete(this.deleteObject.links.verwijderen)
        .then(() => {
          this.$emit('on-deleted');
        })
        .catch((error) => {
          this.$emit('on-error', error.response.data?.errors);
        })
        .finally(() => this.$emit('close'));
    } else {
      this.$emit('delete');
    }
  }

  annuleer() {
    this.$emit('annuleer');
  }
}














import {Component, Prop, Vue} from 'vue-property-decorator';
import IconButton from '@/components/button/IconButton.vue';

@Component({
  components: { IconButton },
})
export default class CombinedInputWithIconButton extends Vue {
  @Prop({ default: true }) readonly showIcon!: boolean;
  @Prop({ default: 'mdi-close' }) readonly icon!: string;

  onIconButtonClick() {
    this.$emit('click-icon');
  }
}






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class FooterLink extends Vue {
  @Prop(String) href!: string;
  @Prop(String) content!: string;
}

import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import axios from 'axios';
import { GebruikerInterface } from '@/domain/interface/GebruikerInterface';
import AuthService from '@/services/AuthorizationService';
const auth = new AuthService();

const CONTROLEUR = 'Controleur';

@Module({ namespaced: true })
class Gebruiker extends VuexModule {
  public gebruiker: GebruikerInterface = {
    voornaam: '',
    naam: '',
    roepnaam: '',
    erkenningsnummer: '',
    erkenningsstatus: '',
    doelgroep: '',
    registratierollen: [''],
    onderneming: undefined,
  };

  @Mutation
  public setGebruiker(gebruiker: GebruikerInterface): void {
    this.gebruiker = gebruiker;
  }

  @Action
  async updateGebruiker(): Promise<void> {
    try {
      const resp = await axios.get('/gebruiker/info');
      this.context.commit('setGebruiker', resp.data);
    } catch (error) {
      //fout? Gebruiker heeft geen toegang, dan de data uit de token tonen.
      auth.getUser().then((user) => {
        const jwtData = {
          voornaam: user?.profile.given_name,
          naam: user?.profile.family_name,
          roepnaam: '',
          erkenningsnummer: '',
          erkenningsstatus: '',
          doelgroep: '',
          registratierollen: [''],
          onderneming: {
            kboNummer: user?.profile.vo_orgcode,
            ondernemingsnaam: user?.profile.vo_orgnaam,
          },
        };
        this.context.commit('setGebruiker', jwtData);
      });
    }
  }

  get voornaam(): string {
    return this.gebruiker.voornaam;
  }

  get naam(): string {
    return this.gebruiker.naam;
  }

  get roepnaam(): string {
    return this.gebruiker.roepnaam ? this.gebruiker.roepnaam : '';
  }

  get volledigeNaam(): string {
    const voornaam = this.gebruiker.roepnaam
      ? this.roepnaam
      : this.gebruiker.voornaam;

    return this.gebruiker.naam + ', ' + voornaam;
  }

  get erkenningsnummer(): string {
    return this.gebruiker.erkenningsnummer
      ? this.gebruiker.erkenningsnummer
      : '';
  }

  get erkenningsstatus(): string {
    return this.gebruiker.erkenningsstatus
      ? this.gebruiker.erkenningsstatus
      : '';
  }

  get doelgroep(): string {
    return this.gebruiker.doelgroep;
  }

  get registratierollen(): string {
    return this.gebruiker.registratierollen
      ? this.gebruiker.registratierollen.join(', ')
      : '';
  }

  get ondernemingInfo(): string {
    return this.gebruiker.onderneming?.kboNummer &&
      this.gebruiker.onderneming?.ondernemingsnaam
      ? `${this.gebruiker.onderneming.kboNummer} ${this.gebruiker.onderneming.ondernemingsnaam}`
      : '';
  }

  get isControleur(): boolean {
    return this.gebruiker.registratierollen.includes(CONTROLEUR);
  }
}

export default Gebruiker;

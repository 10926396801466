import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { CopiedFromObjectLink } from '@/domain/interface/kopieer/CopiedFromObjectLink';

@Module({ namespaced: true })
export default class CopyPasteStore extends VuexModule {
  public copiedGebouweenheid: CopiedFromObjectLink = new CopiedFromObjectLink(
    null,
    null
  );

  @Mutation
  saveCopyGebouweenheidLink(gebouweenheidLink: CopiedFromObjectLink) {
    this.copiedGebouweenheid = gebouweenheidLink;
  }

  @Mutation
  resetCopyGebouweenheidLink() {
    this.copiedGebouweenheid = new CopiedFromObjectLink(null, null);
  }

  get getCopyGebouweenheidLink(): CopiedFromObjectLink {
    return this.copiedGebouweenheid;
  }
}

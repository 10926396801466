import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import axios from 'axios';
import { EnergiestroomInterface } from '@/domain/interface/energiestroom/EnergiestroomInterface';
import { MeterInterface } from '@/domain/interface/energiestroom/MeterInterface';
import { LinkInterface } from '@/domain/interface/LinkInterface';

@Module({ namespaced: true })
class Energiestroom extends VuexModule {
  public energiestroom: EnergiestroomInterface = {
    id: '',
    naam: '',
    energiestroomType: undefined,
    producent: undefined,
    producentSubtype: undefined,
    energiedrager: undefined,
    hernieuwbaarBekend: false,
    hernieuwbaarRestwarmte: undefined,
    hernieuwbaarNietRestwarmte: undefined,
    nietHernieuwbaarRestwarmte: undefined,
    nietHernieuwbaarNietRestwarmte: undefined,
    uitgaandeEnergieType: undefined,
    meters: [],
    gekoppeldeEnergiestromenBekend: false,
    meetpuntOokVoorGeexporteerdeStroom: false,
    gekoppeldeEnergiestromen: [],
    links: {},
  };
  public energiestroomLoading = false;
  public energiestroomWithMetersLoading = false;

  @Mutation
  public setEnergiestroom(energiestroom: EnergiestroomInterface): void {
    this.energiestroom = energiestroom;
  }

  @Mutation
  public setEnergiestroomLoading(loading: boolean): void {
    this.energiestroomLoading = loading;
  }

  @Mutation
  public setEnergiestroomWithMetersLoading(loading: boolean): void {
    this.energiestroomWithMetersLoading = loading;
  }

  @Mutation
  public setMeters(meters: MeterInterface[]): void {
    this.energiestroom.meters = meters;
  }

  @Action
  public loadEnergiestroom(id: string): Promise<void> {
    this.context.commit('setEnergiestroomLoading', true);
    return axios
      .get('/energiestromen/' + id)
      .then((resp) => {
        this.context.commit('setEnergiestroom', resp.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        this.context.commit('setEnergiestroomLoading', false);
      });
  }

  @Action
  public async loadEnergiestroomWithMeters(id: string): Promise<void> {
    this.context.commit('setEnergiestroomWithMetersLoading', true);
    return this.context.dispatch('loadEnergiestroom', id).then(() => {
      this.context.dispatch('loadMeters').then(() => {
        this.context.commit('setEnergiestroomWithMetersLoading', false);
      });
    });
  }

  @Action
  async loadMeters(): Promise<MeterInterface[]> {
    const meterLoaders: Promise<MeterInterface>[] = [];
    if (this.energiestroom.links.meters) {
      await axios.get(this.energiestroom.links.meters).then((resp) => {
        this.context.commit('setMeters', resp.data);
        this.context.getters.meters.forEach((meter: MeterInterface) => {
          meterLoaders.push(this.context.dispatch('loadMeterDetail', meter));
        });
      });
    }
    return Promise.all(meterLoaders);
  }

  @Action
  loadMeterDetail(meter: MeterInterface): Promise<MeterInterface> {
    return new Promise<MeterInterface>((resolve, reject) => {
      if (meter.links?.detail) {
        axios
          .get(meter.links?.detail)
          .then((resp) => resp.data)
          .then((data) => {
            const index = this.meters.findIndex(
              (meter) => meter.id === data.id
            );
            this.meters.splice(index, 1, data);
            resolve(data);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      } else {
        reject('Geen detaillink aanwezig');
      }
    });
  }

  get id(): string {
    return this.energiestroom.id;
  }

  get links(): LinkInterface {
    return this.energiestroom.links;
  }

  get meters(): MeterInterface[] {
    return this.energiestroom.meters;
  }

  get getLoading(): boolean {
    return this.energiestroomLoading;
  }

  get getMetersLoading(): boolean {
    return this.energiestroomWithMetersLoading;
  }

  get getEnergiestroom(): EnergiestroomInterface {
    return this.energiestroom;
  }
}

export default Energiestroom;

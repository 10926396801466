






















import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import IconButton from '@/components/button/IconButton.vue';
import NotificationService from '@/services/NotificationService';

@Component({
  components: { IconButton },
})
export default class SuccessAlert extends Vue {
  @Inject('notificationService') notificationService: NotificationService;

  private readonly timeout = 3000;

  created() {
    setTimeout(() => {
      this.notificationService.clearSuccessMessages();
    }, this.timeout);
  }

  @Prop() readonly messages!: [];
}

import Vue from 'vue';
import moment from 'moment';

//Define here the global filters. Those are imported in main.ts, before the vue instance is created.
Vue.filter('formatDate_ddmmyyyy_slash', function (value: string) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY');
  }
});

Vue.filter('formatDate_ddmmyyyyhhmm_slash', function (value: string) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY HH:mm');
  }
});

Vue.filter('formatDate_mmyyyy_slash', function (value: string) {
  if (value) {
    return moment(String(value)).format('MM/YYYY');
  }
});

Vue.filter('formatNumber', function (value: number, precision: number) {
  if (value) {
    if (precision) {
      return value.toFixed(precision);
    } else {
      return value.toFixed(3);
    }
  }
});

Vue.filter(
  'meetperiode',
  function (startMeetperiode: Date, eindMeetperiode: Date) {
    if (startMeetperiode && eindMeetperiode) {
      return (
        moment(startMeetperiode).format('MM/YYYY') +
        ' - ' +
        moment(eindMeetperiode).format('MM/YYYY')
      );
    }
  }
);

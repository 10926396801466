import HttpService from '@/services/HttpService';
import { GebouweenheidClass } from '@/domain/interface/GebouweenheidClass';
import { CopiedFromObjectLink } from '@/domain/interface/kopieer/CopiedFromObjectLink';
import { CopiedObjectRequest } from '@/domain/interface/kopieer/CopiedObjectRequest';
import { Schildeel } from '@/domain/interface/schildeel/Schildeel';
import { BasicInterface } from '@/domain/interface/installaties/installatie/BasicClass';
import { BevochtigingClass } from '@/domain/interface/installaties/bevochtiging/BevochtigingClass';
import { GebouweenheidCalculationResponse } from '@/domain/interface/gebouweenheid/GebouweenheidCalculationResponse';
import { AandeelHernieuwbareEnergieClass } from '@/domain/interface/aandeel-hernieuwbare-energie/AandeelHernieuwbareEnergieClass';
import { GeometrieVloerenClass } from '@/domain/interface/geometrie/GeometrieVloerenClass';
import { GeometrieVloerenRequestDTO } from '@/domain/interface/geometrie/GeometrieVloerenRequestDTO';
import { GeometrieGevelsRequestDTO } from '@/domain/interface/geometrie/GeometrieGevelsRequestDTO';
import { GeometrieGevelsClass } from '@/domain/interface/geometrie/GeometrieGevelsClass';
import { GeometrieDakenRequestDTO } from '@/domain/interface/geometrie/GeometrieDakenRequestDTO';
import { GeometrieDakenClass } from '@/domain/interface/geometrie/GeometrieDakenClass';
import { GeometrieAlgemeenRequestDTO } from '@/domain/interface/geometrie/GeometrieAlgemeenRequestDTO';
import { GeometrieAlgemeenClass } from '@/domain/interface/geometrie/GeometrieAlgemeenClass';
import { AanbevelingClass } from '@/domain/interface/aanbeveling/AanbevelingClass';
import { ValidatieErrorInterface } from '@/domain/interface/validatie/ValidatieErrorInterface';
import RuimteclusterVentilatieClass from '@/domain/interface/installaties/ventilatie/ruimtecluster/RuimteclusterVentilatieClass';

export default class GebouweenheidService {
  private readonly PATH = '/gebouweenheden';

  constructor(public http: HttpService) {}

  getGebouweenheid(id: string): Promise<GebouweenheidClass> {
    return this.http.get<GebouweenheidClass>(`${this.PATH}/${id}`);
  }

  getGebouweenheidByLink(link: string): Promise<GebouweenheidClass> {
    return this.http.get<GebouweenheidClass>(link);
  }

  gebouweenheidImporteren(url: string, importFromId: string): Promise<GebouweenheidClass> {
    return this.http.put<GebouweenheidClass>(url, { importFromId });
  }

  getSchildelen(link: string): Promise<Schildeel[]> {
    return this.http.get<Schildeel[]>(link);
  }

  getBasicInterfaceData(link: string): Promise<BasicInterface[]> {
    return this.http.get<BasicInterface[]>(link);
  }

  getBevochtigingen(link: string): Promise<BevochtigingClass[]> {
    return this.http.get<BevochtigingClass[]>(link);
  }

  createGeometrieVloeren(link: string, requestBody: GeometrieVloerenRequestDTO): Promise<GeometrieVloerenClass> {
    return this.http.post<GeometrieVloerenClass>(link, requestBody);
  }

  createGeometrieGevel(link: string, requestBody: GeometrieGevelsRequestDTO): Promise<GeometrieGevelsClass> {
    return this.http.post<GeometrieGevelsClass>(link, requestBody);
  }

  createGeometrieDaken(link: string, requestBody: GeometrieDakenRequestDTO): Promise<GeometrieDakenClass> {
    return this.http.post<GeometrieDakenClass>(link, requestBody);
  }

  createGeometrieAlgemeen(link: string, requestBody: GeometrieAlgemeenRequestDTO): Promise<GeometrieAlgemeenClass> {
    return this.http.post<GeometrieAlgemeenClass>(link, requestBody);
  }

  copyPasteGebouweenheid(link: string, copyFromObjectLink: CopiedFromObjectLink) {
    return this.http.put(link, new CopiedObjectRequest(copyFromObjectLink));
  }

  getEnergiescore(link: string): Promise<GebouweenheidCalculationResponse> {
    return this.http.get<GebouweenheidCalculationResponse>(link);
  }

  getAanbevelingen(link: string): Promise<AanbevelingClass[]> {
    return this.http.get<AanbevelingClass[]>(link);
  }

  getAandeelHernieuwbareEnergie(link: string): Promise<AandeelHernieuwbareEnergieClass> {
    return this.http.get<AandeelHernieuwbareEnergieClass>(link);
  }

  berekenGebouweenheid(link: string, berekendVan: string): Promise<GebouweenheidCalculationResponse> {
    return this.http.post<GebouweenheidCalculationResponse>(
      link,
      {},
      {
        params: {
          berekendVan: berekendVan,
        },
      }
    );
  }

  berekenAandeelHernieuwbareEnergie(link: string, berekendVan: string): Promise<AandeelHernieuwbareEnergieClass> {
    return this.http.get<AandeelHernieuwbareEnergieClass>(link, {
      params: {
        berekendVan: berekendVan,
      },
    });
  }

  updateFeedbackEnergiedeskundige(link: string, requestBody: unknown): Promise<GebouweenheidClass> {
    return this.http.put<GebouweenheidClass>(link, requestBody);
  }

  private validateScore(link: string): Promise<ValidatieErrorInterface[]> {
    return this.http.get<ValidatieErrorInterface[]>(link);
  }

  validateEnergiescore(link: string): Promise<ValidatieErrorInterface[]> {
    return this.validateScore(link);
  }

  validateAandeelHernieuwbareEnergie(link: string): Promise<ValidatieErrorInterface[]> {
    return this.validateScore(link);
  }

  saveGebouweenheidImage(
    link: string,
    requestBody: FormData,
    imageName: string,
    isBestemmingAndereDanNietResidentieel: boolean
  ): Promise<GebouweenheidClass> {
    return this.http.post<GebouweenheidClass>(link, requestBody, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        naam: imageName,
        bestemmingAndereDanNietResidentieel: isBestemmingAndereDanNietResidentieel,
      },
    });
  }

  deleteFeedbackEnergiedeskundige(link: string): Promise<void> {
    return this.http.delete(link);
  }

  getRuimteclusterVentilatie(link: string): Promise<RuimteclusterVentilatieClass[]> {
    return this.http.get<RuimteclusterVentilatieClass[]>(link);
  }
}

import HttpService from '@/services/HttpService';
import { Certificaat, CertificaatFilter } from '@/domain/interface/certificaten/certifcaatTypes';
import { Paginated } from '@/domain/interface/Paginated';
import { CertificaatClass } from '@/domain/interface/certificaten/CertificaatClass';
import { CertificaatFormDTO } from '@/domain/interface/certificaten/form/CertificaatFormDTO';
import { flattenSort } from '@/util/filters/filterUtil';

export default class CertificaatService {
  private readonly PATH = '/certificaten';

  constructor(public http: HttpService) {}

  getCertificaten(filter: CertificaatFilter): Promise<Paginated<Certificaat>> {
    return this.http.post(
      `${this.PATH + '/filter'}?page=${filter.page}&size=${filter.size}${flattenSort(filter.sort)}`,
      filter.filter
    );
  }

  getCertificaat(id: string): Promise<CertificaatClass> {
    return this.http.get<CertificaatClass>(`${this.PATH}/${id}`);
  }
  updateCertificaat(link: string, requestBody: CertificaatFormDTO) {
    return this.http.put<CertificaatClass>(link, {
      reden: requestBody.reden,
      status: requestBody.status.code,
    });
  }
}

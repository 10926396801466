import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { OpdrachtClass } from '@/domain/interface/opdracht/OpdrachtClass';
import axios from 'axios';
import { StatusEnum } from '@/domain/interface/opdracht/StatusEnum';

@Module({ namespaced: true })
export default class Opdracht extends VuexModule {
  public status: StatusEnum = StatusEnum.NULL;

  public opdracht: OpdrachtClass = new OpdrachtClass();

  @Action
  async loadOpdracht(id: string) {
    const resp = await axios.get('/opdrachten/' + id);
    this.context.commit('setOpdracht', resp.data);
  }

  @Action
  updateOpdracht(opdracht: OpdrachtClass): void {
    this.context.commit('setOpdracht', opdracht);
  }

  @Mutation
  public setOpdracht(opdracht: OpdrachtClass): void {
    this.opdracht = opdracht;
  }

  @Action
  public resetOpdracht(): void {
    this.context.commit('setOpdracht', new OpdrachtClass());
  }

  get getOpdracht(): OpdrachtClass {
    return this.opdracht;
  }

  get naam(): string {
    return this.opdracht.naam;
  }

  set naam(naam) {
    this.opdracht.naam = naam;
  }

  get id(): string {
    return this.opdracht.id;
  }

  isWizardGoBackButtonVisible() {
    return this.isReadonly;
  }

  get isReadonly(): boolean {
    return this.opdracht.readonly;
  }

  get statusIngediend() {
    return this.opdracht.status?.code === StatusEnum.INGEDIEND;
  }

  get downloadZipCertificaatUrl() {
    return this.opdracht.links.downloadZipCertificaten;
  }

  get downloadCertificatenCount() {
    return this.opdracht.aantalCertificaten
  }
}

import { namespace } from 'vuex-class';
import Component from 'vue-class-component';
import store from '@/store';
import Vue from 'vue';
import { Filter, PagingInfo } from '@/domain/interface/Filter';
import { CertificaatFilter } from '@/domain/interface/certificaten/certifcaatTypes';

const FilterStore = namespace('Filter');
@Component({
  store,
})
export default class FilterService extends Vue {
  getCertificaatFilter(): CertificaatFilter {
    return JSON.parse(JSON.stringify(this.certificaatFilter));
  }

  getOpdrachtFilter(): Filter {
    return JSON.parse(JSON.stringify(this.opdrachtFilter));
  }

  getOpdrachtPagingInfo(): PagingInfo {
    return JSON.parse(JSON.stringify(this.opdrachtPagingInfo));
  }

  @FilterStore.Action
  public updateCertificaatFilter!: (filter: CertificaatFilter) => void;

  @FilterStore.Action
  public updateOpdrachtFilter!: (filter: Filter) => void;

  @FilterStore.Action
  public updateOpdrachtPagingInfo!: (pagingInfo: PagingInfo) => void;

  @FilterStore.Action
  public resetOpdrachtFilter!: () => void;

  @FilterStore.Action
  public resetCertificaatFilter!: () => void;

  @FilterStore.Getter('getCertificaatFilter')
  private certificaatFilter!: CertificaatFilter;

  @FilterStore.Getter('getOpdrachtFilter')
  private opdrachtFilter!: Filter;

  @FilterStore.Getter('getOpdrachtPagingInfo')
  private opdrachtPagingInfo!: PagingInfo;
}























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class IconBlockArrow extends Vue {
  @Prop(String) text?: string;
  @Prop({ default: 'black' }) color!: string;
}

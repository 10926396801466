import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import VueI18n from 'vue-i18n';
import LocaleMessage = VueI18n.LocaleMessage;

export interface SuccesMessage {
  message: string | LocaleMessage;
}

@Module({ namespaced: true })
class SuccessAlertMessages extends VuexModule {
  public messages: SuccesMessage[] = [];

  @Mutation
  public setMessages(messages: SuccesMessage[]): void {
    this.messages = messages;
  }

  @Action
  public updateMessages(messages: SuccesMessage[]) {
    this.context.commit('setMessages', messages);
    store.dispatch('Loader/updateLoaderDone');
  }

  @Action
  public resetMessages() {
    this.context.commit('setMessages', []);
  }

  get getMessages() {
    return this.messages;
  }
}

export default SuccessAlertMessages;








































































































import { Component, Inject, Prop, Provide, Vue } from 'vue-property-decorator';
import { OpdrachtClass } from '@/domain/interface/opdracht/OpdrachtClass';
import InfoCard from '@/components/card/InfoCard.vue';
import { MenuItemInterface } from '@/domain/interface/intern/MenuItemInterface';
import OpdrachtDeleteDialog from '@/components/dialog/OpdrachtDeleteDialog.vue';
import HernieuwbareEnergieScore from '@/views/partials/hernieuwbareEnergie/partials/HernieuwbareEnergieScore.vue';
import IconBlockArrow from '@/components/icon/custom/IconBlockArrow.vue';
import MenuButton from '@/components/menu/MenuButton.vue';
import DownloadService from '@/services/DownloadService';

@Component({
  components: {
    MenuButton,
    IconBlockArrow,
    HernieuwbareEnergieScore,
    InfoCard,
    OpdrachtDeleteDialog,
  },
})
export default class OpdrachtCard extends Vue {
  @Prop(Object) readonly opdracht!: OpdrachtClass;
  @Provide() showDeleteDialog = false;

  @Inject('downloadService')
  private downloadService: DownloadService;

  get items() {
    return [
      {
        label: this.$t('opdrachten.meetperiode'),
        value: this.$options.filters?.meetperiode(this.opdracht?.startMeetperiode, this.opdracht?.eindeMeetperiode),
      },
      {
        label: '',
        value: this.opdracht?.gebruikerErkenningsnummer + ' - ' + this.opdracht?.gebruikerVolledigeNaam(),
      },
    ];
  }

  get downloadCertificaatVisible(): string {
    return this.opdracht.links && this.opdracht.links.downloadZipCertificaten;
  }

  get downloadCertificaatCount(): number {
    return this.opdracht.aantalCertificaten;
  }

  downloadCertificaat() {
    if (this.opdracht.links && this.opdracht.links.downloadZipCertificaten) {
      this.downloadService.downloadFile(this.opdracht.links.downloadZipCertificaten, 'certificaten.zip');
    }
  }

  get menuItems(): MenuItemInterface[] {
    const menuItems = [];
    if (this.opdracht.links.wijzigen) {
      menuItems.push({
        label: this.$t('acties.aanpassen'),
        to: {
          name: 'opdrachtBewerken',
          params: {
            opdrachtUuid: this.opdracht?.id || 'undefined',
          },
        },
        action: this.$t('acties.aanpassen').toString(),
      });
    }

    if (this.opdracht?.links?.dupliceren) {
      menuItems.push({
        label: this.$t('acties.dupliceren'),
        to: {
          name: 'opdrachtDupliceren',
          params: {
            opdrachtUuid: this.opdracht?.id || 'undefined',
          },
        },
        action: this.$t('acties.dupliceren').toString(),
      });
    }
    if (this.opdracht?.links?.verwijderen) {
      menuItems.push({
        label: this.$t('acties.verwijderen'),
        to: {}, //not needed, is handled in OpdrachtDeleteDialog.vue
        action: this.$t('acties.verwijderen').toString(),
      });
    }

    return menuItems;
  }

  clickItem(item: MenuItemInterface) {
    if (item.action === this.$t('acties.verwijderen') && this.opdracht?.links?.verwijderen) {
      this.showDeleteDialog = true;
    } else {
      this.$emit('click-item', item);
    }
  }

  sluitDialoog() {
    this.showDeleteDialog = false;
  }

  onDelete() {
    this.sluitDialoog();
    this.$emit('delete-item');
  }

  formatDate(date: Date) {
    return this.$options.filters?.formatDate_ddmmyyyy_slash(date);
  }
}

import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { EnumInterface } from '@/domain/interface/intern/enumvalues/EnumInterface';
import { fetchIndependentEnum } from '@/util/enum/enumUtils';

//In deze module houden we de enumwaarden bij die over meerdere pagina's gebruikt worden en redelijk statisch zijn.
@Module({ namespaced: true })
class Enums extends VuexModule {
  private _energiestroomType: EnumInterface[] = [];
  private _producent: EnumInterface[] = [];
  private _schildeelTypes: EnumInterface[] = [];
  private _installatieTypes: EnumInterface[] = [];

  @Mutation
  public setEnergiestroomType(stromen: EnumInterface[]): void {
    this._energiestroomType = stromen;
  }

  @Mutation
  public setProducent(producenten: EnumInterface[]): void {
    this._producent = producenten;
  }

  @Mutation
  public setSchildeelTypes(schildeelTypes: EnumInterface[]): void {
    this._schildeelTypes = schildeelTypes;
  }

  @Mutation
  public setInstallatieTypes(installatieTypes: EnumInterface[]): void {
    this._installatieTypes = installatieTypes;
  }

  @Action
  public fetchEnergiestroomType(): Promise<void> {
    return fetchIndependentEnum('energiestroomType').then((data) => this.context.commit('setEnergiestroomType', data));
  }

  @Action
  public fetchProducenten(): void {
    fetchIndependentEnum('producent').then((data) => this.context.commit('setProducent', data));
  }

  @Action
  public fetchSchildeelTypes(): void {
    fetchIndependentEnum('schildeelType').then((data) => this.context.commit('setSchildeelTypes', data));
  }

  @Action
  public fetchInstallatieTypes(): void {
    fetchIndependentEnum('InstallatieType').then((data) => this.context.commit('setInstallatieTypes', data));
  }

  get energiestroomType(): EnumInterface[] {
    return this._energiestroomType;
  }

  get producent(): EnumInterface[] {
    return this._producent;
  }

  get schildeelTypes(): EnumInterface[] {
    return this._schildeelTypes;
  }

  get installatieTypes(): EnumInterface[] {
    return this._installatieTypes;
  }
}

export default Enums;

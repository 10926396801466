import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import {
  DISPATCH_INPUT_EVENT,
  INPUT_UPDATE_EVENT,
  MIXIN_FORM_NAME,
} from '@/util/constants/constants';
import { namespace } from 'vuex-class';

const Opdracht = namespace('Opdracht');

//Deze mixin dispatcht een event naar een parent die de FormMixin implementeert.
//Dit gebeurt dankzij de update-events uit de verschillende input-Mixins

//Dit component kan ook geïmplementeerd worden op andere locaties wanneer we een update-event willen sturen onafhankelijk van inputvelden.
@Component
export default class BaseInputMixin extends Vue {
  created() {
    this.enableInputUpdateDispatch();
  }

  beforeDestroy() {
    //Bij het destroyen van events willen we geen update-input meer sturen naar de parent-form.
    this.disableInputUpdateDispatch();
  }

  enableInputUpdateDispatch() {
    //Bij een dispatch-input event, wordt er gezocht naar een parent met de naam "FORM".
    //Naar die parent emitten we een "input-update"
    this.$on(DISPATCH_INPUT_EVENT, () => {
      this.$dispatchInputEvent();
    });
  }

  disableInputUpdateDispatch() {
    this.$off(DISPATCH_INPUT_EVENT);
  }

  $dispatchInputEvent() {
    let parent = this.$parent || this.$root;
    let name = parent.$options.name;
    while (parent && (!name || name !== MIXIN_FORM_NAME)) {
      parent = parent.$parent;
      if (parent) {
        name = parent.$options.name;
      }
    }
    if (parent) {
      parent.$emit(INPUT_UPDATE_EVENT);
    }
  }

  @Opdracht.Getter('isReadonly')
  public isReadOnly!: boolean;
}

import { namespace } from 'vuex-class';
import { ErrorInterface } from '@/store/modules/errors';
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import store from '@/store';
import { SuccesMessage } from '@/store/modules/successAlertMessages';
import { WarningMessage } from '@/store/modules/warningAlertMessages';

const Errors = namespace('Errors');
const SuccessAlertMessages = namespace('SuccessAlertMessages');
const WarningAlertMessages = namespace('WarningAlertMessages');

@Component({
  store, //Needed to dispatch errors
})
export default class NotificationService extends Vue {
  public showError(error: ErrorInterface) {
    this.updateErrors([error]);
  }

  public showMultipleErrors(errors: ErrorInterface[]) {
    this.updateErrors(errors);
  }

  public clearErrors() {
    this.resetErrors();
  }

  public showSuccessMessage(message: SuccesMessage) {
    this.updateMessages([message]);
  }

  public clearSuccessMessages() {
    this.resetMessages();
  }

  public showWarning(warning: WarningMessage) {
    this.updateWarnings([warning]);
  }

  public clearWarnings() {
    this.resetWarnings();
  }

  @Errors.Action
  private updateErrors!: (errors: ErrorInterface[]) => void;

  @Errors.Action
  public resetErrors!: () => void;

  @SuccessAlertMessages.Action
  public updateMessages!: (messages: SuccesMessage[]) => void;

  @SuccessAlertMessages.Action
  public resetMessages!: () => void;

  @WarningAlertMessages.Action
  public updateWarnings!: (messages: WarningMessage[]) => void;

  @WarningAlertMessages.Action
  public resetWarnings!: () => void;
}

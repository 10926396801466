























































import { Component, Vue } from 'vue-property-decorator';
import IconKruisAppendix from '@/components/icon/custom/templates/IconKruisAppendix.vue';

@Component({ components: { IconKruisAppendix } })
export default class IconZonneEnergieKruis extends Vue {}

import HttpService from '@/services/HttpService';
import {GebouwClass} from '@/domain/interface/gebouw/GebouwClass';

export default class GebouwService {
  private readonly PATH_GEBOUWEN = '/gebouwen';

  constructor(public http: HttpService) {}
    getGebouwDetail(id: string): Promise<GebouwClass[]> {
    return this.http.get<GebouwClass[]>(`${this.PATH_GEBOUWEN}/${id}/gebouweenheden`);
  }

  getGebouwen(url: string): Promise<GebouwClass[]> {
    return this.http.get<GebouwClass[]>(url);
  }
}



















































































































import { Component, Prop } from 'vue-property-decorator';
import EnumConstantsMixin from '@/mixins/EnumConstantsMixin';
import { mixins } from 'vue-class-component';
import ProgressCircleRow from '@/components/progress/ProgressCircleRow.vue';
import { AandeelHernieuwbareEnergieClass } from '@/domain/interface/aandeel-hernieuwbare-energie/AandeelHernieuwbareEnergieClass';
import IconBlockArrow from '@/components/icon/custom/IconBlockArrow.vue';
import { i18n } from '@/i18n';

@Component({
  methods: {
    i18n() {
      return i18n;
    },
  },
  components: { IconBlockArrow, ProgressCircleRow },
})
export default class HernieuwbareEnergieScore extends mixins(EnumConstantsMixin) {
  @Prop(Object) calculationResponse!: AandeelHernieuwbareEnergieClass;
}

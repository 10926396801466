import {Component, Prop, Provide} from 'vue-property-decorator';
import BaseInputMixin from '@/mixins/inputMixins/base/BaseInputMixin';
import {DISPATCH_INPUT_EVENT} from '@/util/constants/constants';

@Component
export default class InputMixin extends BaseInputMixin {
  created() {
    if (!this.hasChild) {
      this.$on('input', () => {
        this.$emit(DISPATCH_INPUT_EVENT);
      });
    }
  }

  @Provide() value: string | object | [] | number | undefined;
  @Prop() defaultValue!: string | object | [] | number | undefined;
  @Prop({default: false}) hasChild: boolean;

  beforeDestroy() {
    this.$emit('input', this.defaultValue);
  }
}

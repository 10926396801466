import { LinkInterface } from '@/domain/interface/LinkInterface';
import { EnumInterface } from '@/domain/interface/intern/enumvalues/EnumInterface';
import { AandeelHernieuwbareEnergieClass } from '@/domain/interface/aandeel-hernieuwbare-energie/AandeelHernieuwbareEnergieClass';

export class OpdrachtClass {
  id: string;
  naam: string;
  gebruikerNaam: string;
  gebruikerVoornaam: string;
  gebruikerRoepnaam: string;
  gebruikerErkenningsnummer: string;
  datumPlaatsbezoek?: Date;
  status?: EnumInterface;
  statusDatum?: Date;
  nummer?: string;
  startMeetperiode?: Date;
  eindeMeetperiode?: Date;
  periodeJaarInterval?: string;
  prijsEpc?: string;
  verplichteMeetgegevensAfwezig?: boolean;
  opmerkingMeetgegevens?: string;
  readonly: boolean;
  links: LinkInterface;
  aandeelHernieuwbareEnergie: AandeelHernieuwbareEnergieClass;
  aantalCertificaten: number;

  constructor(opdracht?: OpdrachtClass) {
    this.id = opdracht?.id || '';
    this.naam = opdracht?.naam || '';
    this.gebruikerNaam = opdracht?.gebruikerNaam || '';
    this.gebruikerVoornaam = opdracht?.gebruikerVoornaam || '';
    this.gebruikerRoepnaam = opdracht?.gebruikerRoepnaam || '';
    this.gebruikerErkenningsnummer = opdracht?.gebruikerErkenningsnummer || '';
    this.datumPlaatsbezoek = opdracht?.datumPlaatsbezoek;
    this.status = opdracht?.status;
    this.statusDatum = opdracht?.statusDatum;
    this.nummer = opdracht?.nummer;
    this.startMeetperiode = opdracht?.startMeetperiode;
    this.eindeMeetperiode = opdracht?.eindeMeetperiode;
    this.periodeJaarInterval = opdracht?.periodeJaarInterval;
    this.prijsEpc = opdracht?.prijsEpc;
    this.verplichteMeetgegevensAfwezig =
      opdracht?.verplichteMeetgegevensAfwezig;
    this.opmerkingMeetgegevens = opdracht?.opmerkingMeetgegevens;
    this.readonly = opdracht?.readonly || false;
    this.links = opdracht?.links || {};
    this.aandeelHernieuwbareEnergie =
      opdracht?.aandeelHernieuwbareEnergie ||
      ({} as AandeelHernieuwbareEnergieClass);
    this.aantalCertificaten = opdracht?.aantalCertificaten || 0;
  }

  gebruikerVolledigeNaam() {
    if (this.gebruikerRoepnaam) {
      return this.gebruikerNaam + ', ' + this.gebruikerRoepnaam;
    }
    return this.gebruikerNaam + ', ' + this.gebruikerVoornaam;
  }
}

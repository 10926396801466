

















































import { Component, Prop } from 'vue-property-decorator';
import MenuButton from '@/components/menu/MenuButton.vue';
import { MenuItemInterface } from '@/domain/interface/intern/MenuItemInterface';
import CollapsibleIconButton from '@/components/button/CollapsibleIconButton.vue';
import BaseInputMixin from '@/mixins/inputMixins/base/BaseInputMixin';
import { mixins } from 'vue-class-component';

@Component({
  components: { CollapsibleIconButton, MenuButton },
})
export default class SubtitleCollapsibleWithAdd extends mixins(BaseInputMixin) {
  @Prop({ default: false }) value!: boolean;
  @Prop(String) readonly title?: string;
  @Prop(String) readonly subtitle?: string;
  @Prop({ default: false }) readonly collapsible!: boolean;
  @Prop({ default: true }) readonly addButtonVisible!: boolean;
  @Prop(Array) readonly menuItems!: MenuItemInterface[];

  clickPlusButton() {
    this.$emit('add');
  }

  clickCollapseButton() {
    this.$emit('input', !this.value);
  }

  clickItem(item: MenuItemInterface) {
    this.$emit('click-item', item);
  }
}

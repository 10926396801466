import { SortConfig } from '@/domain/interface/certificaten/certifcaatTypes';

export function flattenSort(sort: SortConfig[]) {
  return sort.map((s) => `&sortBy=${s.property}&sortDir=${s.direction}`).join('');
}

export function determineIfActiveArray(array: any[]): boolean {
  return array?.length > 0;
}

export function determineIfActiveBoolean(bool: boolean): boolean {
  return bool != null;
}

export function determineIfActiveObject(obj: any): boolean {
  return !!obj?.van || !!obj?.tot;
}
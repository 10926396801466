




import { Component, Prop, Vue } from 'vue-property-decorator';
import IconButton from '@/components/button/IconButton.vue';

@Component({
  components: { IconButton },
})
export default class CollapsibleIconButton extends Vue {
  @Prop() readonly collapsed!: boolean;

  get icon() {
    return this.collapsed ? 'mdi-chevron-down' : 'mdi-chevron-right';
  }
}

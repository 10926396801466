

















































































































































































































































































































































































































import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';
import DatePicker from '@/components/form/DatePicker.vue';
import NumberInput from '@/components/form/NumberInput.vue';
import { Filter } from '@/domain/interface/Filter';
import { fetchIndependentEnum } from '@/util/enum/enumUtils';
import GebruikerService from '@/services/GebruikerService';
import { EnumInterface } from '@/domain/interface/intern/enumvalues/EnumInterface';
import ActiveFilterIndicator from '@/views/partials/filter/ActiveFilterIndicator.vue';
import {determineIfActiveArray, determineIfActiveBoolean, determineIfActiveObject} from '@/util/filters/filterUtil';

@Component({
  methods: {determineIfActiveObject, determineIfActiveBoolean, determineIfActiveArray},
  components: { ActiveFilterIndicator, NumberInput, DatePicker },
})
export default class SidePanelFilter extends Vue {
  @Inject('gebruikerService') gebruikerService: GebruikerService;
  @Prop({ default: false }) showFilter: boolean;
  @Prop({ default: false }) isImport: boolean;
  @Prop({ default: Array }) excludedFilters: string[];
  filterHeight = 750;
  filterOptions = {
    energiedeskundigen: [],
    certificaatStatussen: [],
    opdrachtStatussen: [],
    bestemmingen: [],
    booleanOptie: [
      {
        text: 'Ja',
        value: true,
      },
      {
        text: 'Neen',
        value: false,
      },
    ],
    labels: [],
  };
  @Prop() savedFilter: Filter;
  filter: Filter = JSON.parse(JSON.stringify(this.savedFilter));

  @Watch('savedFilter', { deep: true })
  updateFilter() {
    this.filter = JSON.parse(JSON.stringify(this.savedFilter));
  }

  mounted(): void {
    this.gebruikerService.getGebruikers().then((data) => {
      this.filterOptions.energiedeskundigen = data;
    });
    fetchIndependentEnum('DocumentStatus', ['ONGELDIG']).then((items) => {
      this.filterOptions.certificaatStatussen = items;
    });
    fetchIndependentEnum('OpdrachtStatus', ['VEROUDERD']).then((items) => {
      this.filterOptions.opdrachtStatussen = items;
    });
    fetchIndependentEnum('Bestemming', []).then((items) => {
      this.filterOptions.bestemmingen = items;
    });
    fetchIndependentEnum('EnergieLabel', []).then((items) => {
      this.filterOptions.labels = items.sort((a, b) => a.omschrijving.localeCompare(b.omschrijving));
    });
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  }

  handleSave() {
    if (this.validateEnergiescoreInput()) {
      this.$emit('saveFilter', JSON.parse(JSON.stringify(this.filter)));
    }
  }

  cancel() {
    this.filter = JSON.parse(JSON.stringify(this.savedFilter));
    this.$emit('cancelFilter');
  }

  clearData(field: string, subField: string) {
    let filterElement = this.filter[field];
    if (filterElement[subField] !== undefined) {
      filterElement[subField] = null;
    } else {
      filterElement = null;
    }
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize() {
    if (this.isImport) {
      this.filterHeight = 549;
    } else {
      this.filterHeight = window.innerHeight - 65;
    }
  }

  toggleAllOrNothing(values: string[], options: EnumInterface[]): string[] {
    return values.length === options.length ? values.filter(() => false) : options.map((item) => item.code);
  }

  validateEnergiescoreInput(): boolean {
    if (this.filter.energiescore.van !== null && this.filter.energiescore.tot !== null) {
      return this.filter.energiescore.van <= this.filter.energiescore.tot;
    }
    return true;
  }
}



































import { Component, Prop, Vue } from 'vue-property-decorator';
import MenuButton from '@/components/menu/MenuButton.vue';
import { MenuItemInterface } from '@/domain/interface/intern/MenuItemInterface';
import { LocaleMessage } from 'vue-i18n';

@Component({ components: { MenuButton } })
export default class InfoCard extends Vue {
  @Prop() readonly title: string | LocaleMessage | undefined;
  @Prop() readonly items: object[] | undefined;
  @Prop(Array) readonly menuItems!: MenuItemInterface[];
  @Prop() readonly icon: string | undefined;

  clickItem(item: object) {
    this.$emit('click-item', item);
  }
}

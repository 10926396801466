









































import { Component, Prop, Vue } from 'vue-property-decorator';
import { MenuItemCheckboxInterface } from '@/domain/interface/intern/MenuItemCheckboxInterface';

@Component
export default class MenuButtonCheckbox extends Vue {
  @Prop(Array) readonly value!: MenuItemCheckboxInterface[];
  @Prop(Array) readonly items!: MenuItemCheckboxInterface[];
  @Prop(Object) readonly buttonAttrs?: object;
}

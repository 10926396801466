import Component from 'vue-class-component';
import store from '@/store';
import Vue from 'vue';
import { namespace } from 'vuex-class';
import { OpdrachtClass } from '@/domain/interface/opdracht/OpdrachtClass';
import { CopiedFromObjectLink } from '@/domain/interface/kopieer/CopiedFromObjectLink';

const Opdracht = namespace('Opdracht');
const CopyPasteStore = namespace('CopyPasteStore');
@Component({
  store, //Needed get kopieer data + current opdracht id
})
export default class CopyPasteGebouweenheidService extends Vue {
  public copyGebouweenheid(gebouweenheidId: string) {
    this.saveCopyGebouweenheidLink(
      new CopiedFromObjectLink(this.opdracht.id, gebouweenheidId)
    );
  }

  public isGebouweenheidPlakkenMogelijk(gebouweenheidIdFormCard: string) {
    return (
      this.getCopyGebouweenheidLink.opdrachtId === this.opdracht.id &&
      !!this.getCopyGebouweenheidLink.copyFromId &&
      !this.isGebouweenheidCopied(gebouweenheidIdFormCard)
    );
  }
  public isGebouweenheidDeselectMogelijk(gebouweenheidIdFormCard: string) {
    return (
      this.getCopyGebouweenheidLink.opdrachtId === this.opdracht.id &&
      !!this.getCopyGebouweenheidLink.copyFromId &&
      this.isGebouweenheidCopied(gebouweenheidIdFormCard)
    );
  }

  public isGebouweenheidCopyAble(gebouweenheidIdFormCard: string) {
    return !this.isGebouweenheidCopied(gebouweenheidIdFormCard);
  }

  public isGebouweenheidCopied(gebouweenheidId: string): boolean {
    return this.getCopyGebouweenheidLink.copyFromId === gebouweenheidId;
  }

  @CopyPasteStore.Mutation('saveCopyGebouweenheidLink')
  private saveCopyGebouweenheidLink!: (
    gebouweenheidCopyLink: CopiedFromObjectLink
  ) => void;

  @CopyPasteStore.Mutation('resetCopyGebouweenheidLink')
  public resetCopyGebouweenheidLink!: () => void;

  @Opdracht.Getter('getOpdracht')
  private opdracht!: OpdrachtClass;

  @CopyPasteStore.Getter('getCopyGebouweenheidLink')
  public getCopyGebouweenheidLink!: CopiedFromObjectLink;
}

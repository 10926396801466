


































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ObjectInputMixin from '@/mixins/inputMixins/subMixins/ObjectInputMixin';
import { LocaleMessage } from 'vue-i18n';

@Component
export default class AutocompleteInput extends mixins(ObjectInputMixin) {
  @Prop(String) id: string | undefined;
  @Prop(String) label: string | undefined;
  @Prop() readonly value: string | number | object | undefined;
  @Prop(String) search: string | undefined;
  @Prop(String) itemText: string | undefined;
  @Prop({ default: 'algemeen.geen-data' }) noDataTextKey: string | undefined;

  @Prop(Boolean) readonly loading!: boolean;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Boolean) readonly numberInput!: boolean;
  @Prop(Boolean) readonly multiple!: boolean;
  @Prop({ default: false }) hasChild: boolean;

  @Prop({ default: false }) readonly noFilter: boolean | undefined;
  @Prop({ default: false }) readonly hideNoDataIfNoInput: boolean | undefined;

  @Prop({ default: false }) withSubTitles!: boolean;

  @Prop(Array) readonly items!: [];
  @Prop({ default: Array }) readonly rules!: ((v: string) => string | boolean | LocaleMessage)[];
  @Prop()
  filterObject!: Function;
  input = '';

  @Watch('input')
  onChildChanged(search: string) {
    if (this.input) {
      this.$emit('search', search);
    }
  }

  hasItemsSlot() {
    return !!this.$scopedSlots.item;
  }

  hasSelectionSlot() {
    return !!this.$scopedSlots.selection;
  }
}

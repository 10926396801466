import { i18n } from '@/i18n';
import { DataTableHeader } from 'vuetify';
import { CertificaatColumn, CertificaatTableColumns } from '@/domain/interface/certificaten/certifcaatTypes';
import { CERTIFICATEN_TABLE_STORAGE_KEY } from '@/util/constants/constants';
import LocalStorageService from '@/services/LocalStorageService';

export default class CertificatenTableHeaderProvider {
  private links: CertificaatColumn = {
    text: i18n.t('certificaten.table.headers.acties').toString(),
    visible: true,
    fixed: true,
  };
  private iltd = {
    text: i18n.t('certificaten.table.headers.iltd').toString(),
    visible: false,
    fixed: false,
  };

  private visibleColumns: CertificaatTableColumns = {
    datumIngediend: {
      text: i18n.t('certificaten.table.headers.ingediend').toString(),
      visible: true,
      fixed: true,
    },
    energiedeskundige: {
      text: i18n.t('certificaten.table.headers.energiedeskundige').toString(),
      visible: true,
      fixed: true,
    },
    opdrachtnaam: {
      text: i18n.t('certificaten.table.headers.naam-opdracht').toString(),
      visible: true,
      fixed: false,
    },
    naamGebouweenheid: {
      text: i18n.t('certificaten.table.headers.naam-gebouweenheid').toString(),
      visible: true,
      fixed: false,
    },
    bestemming: {
      text: i18n.t('certificaten.table.headers.bestemming').toString(),
      visible: true,
      fixed: false,
    },
    gebouwId: {
      text: i18n.t('certificaten.table.headers.gebouw-id').toString(),
      visible: false,
      fixed: false,
    },
    gebouweenheidId: {
      text: i18n.t('certificaten.table.headers.gebouweenheid-id').toString(),
      visible: false,
      fixed: false,
    },
    gemeente: {
      text: i18n.t('certificaten.table.headers.gemeente').toString(),
      visible: true,
      fixed: false,
    },
    postcode: {
      text: i18n.t('certificaten.table.headers.postcode').toString(),
      visible: true,
      fixed: false,
    },
    adres: {
      text: i18n.t('certificaten.table.headers.adres').toString(),
      visible: true,
      fixed: false,
    },
    certificaatnummer: {
      text: i18n.t('certificaten.table.headers.certificaatnummer').toString(),
      visible: true,
      fixed: true,
    },
    'status.omschrijving': {
      text: i18n.t('certificaten.table.headers.status-certificaat').toString(),
      visible: true,
      fixed: true,
    },
    pdf: {
      text: i18n.t('certificaten.table.headers.pdf').toString(),
      visible: true,
      fixed: true,
    },

    energiescore: {
      text: i18n.t('certificaten.table.headers.energiescore').toString(),
      visible: false,
      fixed: false,
    },
    label: {
      text: i18n.t('certificaten.table.headers.label').toString(),
      visible: false,
      fixed: false,
    },
    iltd: { ...this.iltd },
  };

  private headers: DataTableHeader[] = [
    {
      text: this.visibleColumns.datumIngediend.text,
      align: 'start',
      value: 'datumIngediend',
    },
    {
      text: this.visibleColumns.energiedeskundige.text,
      align: 'start',
      value: 'energiedeskundige',
    },
    {
      text: this.visibleColumns.opdrachtnaam.text,
      align: 'start',
      value: 'opdrachtnaam',
    },
    {
      text: this.visibleColumns.naamGebouweenheid.text,
      align: 'start',
      value: 'naamGebouweenheid',
    },
    {
      text: this.visibleColumns.bestemming.text,
      align: 'start',
      value: 'bestemming',
    },
    {
      text: i18n.t('certificaten.table.headers.gebouw-id').toString(),
      align: 'start',
      value: 'gebouwId',
    },
    {
      text: i18n.t('certificaten.table.headers.gebouweenheid-id').toString(),
      align: 'start',
      value: 'gebouweenheidId',
    },
    {
      text: this.visibleColumns.gemeente.text,
      align: 'start',
      value: 'gemeente',
    },
    {
      text: this.visibleColumns.postcode.text,
      align: 'start',
      value: 'postcode',
    },
    {
      text: this.visibleColumns.adres.text,
      align: 'start',
      value: 'adres',
    },
    {
      text: this.visibleColumns.certificaatnummer.text,
      align: 'start',
      value: 'certificaatnummer',
    },
    {
      text: this.visibleColumns['status.omschrijving'].text,
      align: 'start',
      value: 'status.omschrijving',
    },
    {
      text: this.visibleColumns.energiescore.text,
      align: 'start',
      value: 'energiescore',
    },
    {
      text: this.visibleColumns.label.text,
      align: 'start',
      value: 'label',
    },
    {
      text: this.visibleColumns.iltd.text,
      align: 'start',
      value: 'iltd',
    },
    {
      text: this.visibleColumns.pdf.text,
      align: 'center',
      sortable: false,
      value: 'pdf',
    },
    {
      text: this.links.text,
      sortable: false,
      align: 'start',
      value: 'links',
    },
  ];

  constructor(private localStorageService: LocalStorageService) {
    const certificaatTableColumns: CertificaatTableColumns = this.localStorageService.getItem<CertificaatTableColumns>(
      CERTIFICATEN_TABLE_STORAGE_KEY
    );
    if (certificaatTableColumns) {
      this.visibleColumns = certificaatTableColumns;
      this.migrateLocalStorage();
    }
  }

  private migrateLocalStorage() {
    const oldAheKey = 'ahe';
    if (Object.keys(this.visibleColumns).includes(oldAheKey)) {
      this.visibleColumns.iltd = {
        ...this.iltd,
        visible: this.visibleColumns[oldAheKey].visible,
      };
      delete this.visibleColumns[oldAheKey];
    }
  }

  public provideHeaders(isControleur: boolean): DataTableHeader[] {
    return this.headers
      .filter((header) => header.value !== 'links' || isControleur)
      .filter(
        (header) =>
          this.visibleColumns[header.value as keyof CertificaatColumn]?.visible ||
          this.visibleColumns[header.value as keyof CertificaatColumn]?.fixed
      );
  }

  public showColumns(isControleur: boolean) {
    if (isControleur) {
      this.visibleColumns = {
        ...this.visibleColumns,
        links: this.links,
      };
    } else {
      delete this.visibleColumns.links;
    }

    return this.visibleColumns;
  }
}

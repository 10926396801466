import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Filter as FilterDto, OpdrachtFilter, PagingInfo } from '@/domain/interface/Filter';
import { CertificaatFilter } from '@/domain/interface/certificaten/certifcaatTypes';

@Module({ namespaced: true })
class Filter extends VuexModule {
  private defaultFilter: FilterDto = {
    energiedeskundigen: [],
    certificaatStatussen: [],
    opdrachtStatussen: [],
    bestemmingen: [],
    publiekGebouw: null,
    overheidsgebouw: null,
    aanmaakdatum: {
      van: null,
      tot: null,
    },
    indiendatum: {
      van: null,
      tot: null,
    },
    labels: [],
    energiescore: {
      van: null,
      tot: null,
    },
    searchString: '',
  };
  private opdrachtFilter: OpdrachtFilter = {
    filter: JSON.parse(JSON.stringify(this.defaultFilter)),
    paging: {
      size: 8,
      page: 1,
    },
  };
  private certificaatFilter: CertificaatFilter = {
    size: 10,
    page: 1,
    sort: [],
    filter: JSON.parse(JSON.stringify(this.defaultFilter)),
  };

  @Mutation
  private setOpdrachtFilter(filter: FilterDto): void {
    this.opdrachtFilter.filter = filter;
  }
  @Mutation
  private setCertificaatFilter(filter: CertificaatFilter): void {
    this.certificaatFilter = filter;
  }

  @Mutation
  private setOpdrachtPagingInfo(pagingInfo: PagingInfo): void {
    this.opdrachtFilter.paging = pagingInfo;
  }

  @Action
  public updateOpdrachtFilter(filter: FilterDto): void {
    this.context.commit('setOpdrachtFilter', filter);
  }

  @Action
  public updateCertificaatFilter(filter: CertificaatFilter): void {
    this.context.commit('setCertificaatFilter', filter);
  }

  @Action
  public resetOpdrachtFilter(): void {
    this.context.commit('setOpdrachtFilter', {
      ...this.defaultFilter,
      aanmaakdatum: { van: null, tot: null },
      indiendatum: { van: null, tot: null },
      energiescore: { van: null, tot: null },
      searchString: this.opdrachtFilter.filter.searchString,
    });
  }

  @Action
  public resetCertificaatFilter(): void {
    this.context.commit('setCertificaatFilter', {
      ...this.certificaatFilter,
      filter: {
        ...this.defaultFilter,
        aanmaakdatum: { van: null, tot: null },
        indiendatum: { van: null, tot: null },
        energiescore: { van: null, tot: null },
        searchString: this.certificaatFilter.filter.searchString,
      },
    });
  }

  @Action
  public updateOpdrachtPagingInfo(pagingInfo: PagingInfo): void {
    this.context.commit('setOpdrachtPagingInfo', pagingInfo);
  }

  get getOpdrachtFilter(): FilterDto {
    return this.opdrachtFilter.filter;
  }
  get getCertificaatFilter(): CertificaatFilter {
    return this.certificaatFilter;
  }

  get getOpdrachtPagingInfo(): PagingInfo {
    return this.opdrachtFilter.paging;
  }
}

export default Filter;






















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Filter } from '@/domain/interface/Filter';
import {determineIfActiveArray, determineIfActiveBoolean, determineIfActiveObject} from '@/util/filters/filterUtil';

@Component({})
export default class ActiveFilterIndicator extends Vue {
  @Prop() filter: Filter;

  amountOfActiveFilters(): number {
    return Object.keys(this.filter)
      .map((key) => this.determineIfActive(key))
      .reduce((a, b) => a + b);
  }

  private determineIfActive(key: string): number {
    if (key === 'searchString') {
      return 0;
    }

    if (Array.isArray(this.filter[key])) {
      return determineIfActiveArray(this.filter[key]) ? 1 : 0;
    }

    if (key === 'publiekGebouw' || key === 'overheidsgebouw') {
      return determineIfActiveBoolean(this.filter[key]) ? 1 : 0;
    }

    if (typeof this.filter[key] === 'object') {
      return determineIfActiveObject(this.filter[key]) ? 1 : 0;
    }
    return 0;
  }
}

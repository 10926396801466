import { User, UserManager } from 'oidc-client';
import axios from 'axios';
import {
  ROL_DESK_D,
  ROL_EPB_CONTROLEUR,
  ROL_STUDENT_TYPED,
} from '@/util/constants/constants';
import ConfigHelper from '@/util/ConfigHelper';

export default class AuthorizationService {
  private userManager: UserManager;

  constructor() {
    this.userManager = new UserManager(
      JSON.parse(process.env.VUE_APP_ACM_CONFIG)
    );
  }

  public getUser(): Promise<User | null> {
    return this.userManager.getUser();
  }

  public login(): Promise<void> {
    return this.userManager.signinRedirect();
  }

  //Because the UserManger automatically uses the post_+logout_redirect_uri and we don't want to use this functionality
  // we create a constant usermanager just for the logout that changes the property to undefined so there is no redirect
  public logout(): Promise<void> {
    const newUserManager = new UserManager({
      ...JSON.parse(process.env.VUE_APP_ACM_CONFIG),
      // eslint-disable-next-line @typescript-eslint/camelcase
      post_logout_redirect_uri: undefined,
    });
    console.log(newUserManager);
    newUserManager
      .removeUser()
      .then(() => console.log('User removed from all storages.'));
    return newUserManager.signoutRedirect();
  }

  //To switch to acm we need 3 reauired parameters, the client , switch and post logout redirect uri. THe client id en logout redirect url are defined in the acm config property on which the usermanager is created
  // but because the redirect url is automatically used as query parameter we dont need to provide it in the additional query parameters
  public switch(): Promise<void> {
    this.userManager
      .removeUser()
      .then(() => console.log('User removed from all storages.'));
    return this.userManager.signoutRedirect({
      extraQueryParams: {
        switch: true,
        // eslint-disable-next-line @typescript-eslint/camelcase
        client_id: this.userManager.settings.client_id,
      },
    });
  }

  public isLoggedIn(): Promise<boolean> {
    return this.userManager.getUser().then((user) => {
      return user != null && !user.expired;
    });
  }

  public getIdToken(): Promise<string | undefined> {
    return this.userManager.getUser().then((user) => {
      return user?.id_token;
    });
  }

  public isInRolStudent(): Promise<boolean> {
    return AuthorizationService.isInRol(ROL_STUDENT_TYPED);
  }

  public isInRolDeskundigeTypeD(): Promise<boolean> {
    return AuthorizationService.isInRol(ROL_DESK_D);
  }

  public isInRolControleur(): Promise<boolean> {
    return AuthorizationService.isInRol(ROL_EPB_CONTROLEUR);
  }

  public static isInRol(rol: string): Promise<boolean> {
    return axios.get('/gebruiker/' + rol).then((resp) => {
      return 200 == resp.status;
    });
  }

  public async hasAccess(): Promise<boolean> {
    let isControleur;
    try {
      isControleur = await this.isInRolControleur();
    } catch (error) {
      isControleur = false;
    }

    if (!isControleur && ConfigHelper.isRolDeskundigeD()) {
      return this.isInRolDeskundigeTypeD();
    } else if (isControleur || ConfigHelper.isRolStudentTypeD()) {
      return this.isInRolStudent();
    } else {
      return false;
    }
  }
}

import HttpService from '@/services/HttpService';
import { Schildeel } from '@/domain/interface/schildeel/Schildeel';
import { SchildeelOpbouw } from '@/domain/interface/schildeel/SchildeelOpbouw';
import { ImportContext } from '@/domain/interface/import/ImportTypes';

export default class SchildeelService {
  constructor(public http: HttpService) {}

  schildeelImporteren(
    url: string,
    importData: ImportContext
  ): Promise<SchildeelOpbouw> {
    return this.http.put<SchildeelOpbouw>(url, {
      importFromId: importData.gebouwEenheid,
      type: importData.schildeelType.type.omschrijving,
      begrenzing: importData.schildeelType.begrenzing.omschrijving,
    });
  }

  public getSchildeel(url: string): Promise<Schildeel> {
    return this.http.get<Schildeel>(url);
  }

  public getSchildeelOpbouwen(url: string): Promise<SchildeelOpbouw[]> {
    return this.http.get<SchildeelOpbouw[]>(url);
  }

  public getSchildeelOpbouwDetail(url: string): Promise<SchildeelOpbouw> {
    return this.http.get<SchildeelOpbouw>(url);
  }

  public deleteSchildeelOpbouw(url: string): Promise<void> {
    return this.http.delete(url);
  }

  public dupliceerSchildeelOpbouw(link: string) {
    return this.http.post<SchildeelOpbouw>(link, null);
  }
}

























import { Component, Vue } from 'vue-property-decorator';
import IconVinkjeAppendix from '@/components/icon/custom/templates/IconVinkjeAppendix.vue';

@Component({ components: { IconVinkjeAppendix } })
export default class IconVerwarmingVinkje extends Vue {}





















import { Component, Prop, Provide, Watch } from 'vue-property-decorator';
import { LocaleMessage } from 'vue-i18n';
import { mixins } from 'vue-class-component';
import RulesMixin from '@/mixins/RulesMixin';
import NumberInputMixin from '@/mixins/inputMixins/subMixins/NumberInputMixin';

@Component
//De numberInput verwacht een waarde van het type Number.
//Intern zal deze omgezet worden naar een string om de juiste formattering getoond te worden.
export default class NumberInput extends mixins(NumberInputMixin, RulesMixin) {
  @Prop(Number) declare readonly value: number | undefined;
  @Provide() formattedValue = '';

  @Prop(String) readonly suffix: string | undefined;
  @Prop(String) readonly label: string | undefined;
  @Prop(String) readonly id: string | undefined;
  @Prop(String) readonly prependIcon: string | undefined;
  @Prop(Number) readonly min: number | undefined;
  @Prop(Number) readonly max: number | undefined;
  @Prop({ default: true }) readonly inclusiveMin!: boolean;
  @Prop({ default: true }) readonly inclusiveMax!: boolean;
  @Prop({ default: false }) readonly clearable!: boolean;
  @Prop(Boolean) readonly disabled: boolean | undefined;

  @Prop({ default: 2 }) readonly precision!: number;
  @Prop({ default: Array }) readonly rules!: ((v: string) => string | boolean | LocaleMessage)[];
  minMaxRules = [];
  toggle = true;

  @Watch('min', { immediate: true })
  @Watch('max', { immediate: true })
  public updateMinMaxRules() {
    this.toggle = false;
    const basicRules = [];
    if (this.max !== undefined) {
      if (this.inclusiveMax) {
        basicRules.push(this.$rule.inclusiveMax(this.max));
      } else {
        basicRules.push(this.$rule.max(this.max));
      }
    }

    if (this.min !== undefined) {
      if (this.inclusiveMin) {
        basicRules.push(this.$rule.inclusiveMin(this.min));
      } else {
        basicRules.push(this.$rule.min(this.min));
      }
    }
    if (this.precision) {
      basicRules.push(this.$rule.precision(this.precision));
    }
    this.minMaxRules = basicRules;
    this.$nextTick(() => (this.toggle = true));
  }

  updateValue(value: string) {
    // De numerieke waarde wordt doorgestuurd. Door de Watch op value, zal formatValue() aangeroepen worden.
    const numberValue = Number.parseFloat(Number.parseFloat(value).toFixed(this.precision));
    this.formatValue(numberValue);
    if (numberValue !== this.value) {
      this.$emit('input', !isNaN(numberValue) ? numberValue : null);
    }
  }

  @Watch('value', { immediate: true })
  formatValue(value: number | undefined) {
    // De numerieke value wordt omgezet naar de geformatteerde waarde die op het scherm getoond wordt.
    // Bij elke wijziging van de echte waarde, zal ook de waarde op het scherm geupdated worden.
    // Door immediate op true te zetten, wordt deze functie ook al aangeroepen wanneer value voor het eerst binnenkomt (bij creatie).
    this.formattedValue = value?.toFixed(this.precision) || '';
  }
}

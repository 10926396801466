
















import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import TextInputMixin from '@/mixins/inputMixins/subMixins/TextInputMixin';
import { LocaleMessage } from 'vue-i18n';

@Component
export default class TextInput extends mixins(TextInputMixin) {
  @Prop(String) readonly id: string | undefined;
  @Prop(String) readonly value: string | undefined;
  @Prop(String) readonly label: string | undefined;
  @Prop(String) readonly appendIcon: string | undefined;
  @Prop(Number) readonly maxLength!: number | string | undefined;
  @Prop({ default: Array }) readonly rules!: ((
    v: string
  ) => string | boolean | LocaleMessage)[];
  @Prop(Boolean) readonly disabled!: boolean;
}

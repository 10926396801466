import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { LocaleMessage } from 'vue-i18n';

@Module({ namespaced: true })
class Loader extends VuexModule {
  public loading = false;
  public loadingMessage = '';

  @Mutation
  public setLoading(loading: boolean): void {
    this.loading = loading;
  }

  @Mutation
  public setLoadingMessage(message: string): void {
    this.loadingMessage = message;
  }

  @Action
  public updateLoaderLoading(message?: string | LocaleMessage): void {
    this.context.commit('setLoading', true);
    this.context.commit('setLoadingMessage', message || '');
  }

  @Action
  public updateLoaderDone(): void {
    this.context.commit('setLoading', false);
    this.context.commit('setLoadingMessage', '');
  }

  get getLoading(): boolean {
    return this.loading;
  }

  get getLoadingMessage(): string {
    return this.loadingMessage;
  }
}

export default Loader;

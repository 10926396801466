













import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import IconButton from '@/components/button/IconButton.vue';
import NotificationService from '@/services/NotificationService';

@Component({
  components: { IconButton },
})
export default class Alert extends Vue {
  @Inject('notificationService') notificationService: NotificationService;

  created() {
    setTimeout(() => {
      this.notificationService.clearErrors();
    }, 5000);
  }

  @Prop() readonly errors!: [];
}























import Header from '@/components/layout/Header.vue';
import Footer from '@/components/layout/Footer.vue';
import { Component, Provide, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Breadcrumbs from '@/components/layout/Breadcrumbs.vue';
import ProgressCircleRow from '@/components/progress/ProgressCircleRow.vue';
import Alert from '@/components/layout/Alert.vue';
import SuccessAlert from '@/components/layout/SuccessAlert.vue';
import OpdrachtService from '@/services/OpdrachtService';
import HttpService from '@/services/HttpService';
import SchildeelService from '@/services/SchildeelService';
import CertificaatService from '@/services/CertificaatService';
import CertificatenTableHeaderProvider from '@/util/provider/CertificatenTableHeaderProvider';
import CopyPasteGebouweenheidService from '@/services/kopieer/CopyPasteGebouweenheidService';
import GebouweenheidService from '@/services/GebouweenheidService';
import GebouwService from '@/services/GebouwService';
import GebruikerService from '@/services/GebruikerService';
import LocalStorageService from '@/services/LocalStorageService';
import NotificationService from '@/services/NotificationService';
import WarningAlert from '@/components/layout/WarningAlert.vue';
import DownloadService from '@/services/DownloadService';
import FilterService from '@/services/FilterService';

const Loader = namespace('Loader');
const Errors = namespace('Errors');
const SuccessAlertMessages = namespace('SuccessAlertMessages');
const WarningAlertMessages = namespace('WarningAlertMessages');

@Component({
  components: {
    WarningAlert,
    Alert,
    SuccessAlert,
    ProgressCircleRow,
    Header,
    Footer,
    Breadcrumbs,
  },
})
export default class App extends Vue {
  @Provide('notificationService')
  notificationService: NotificationService = new NotificationService();

  http: HttpService = new HttpService(this.notificationService);

  @Provide('opdrachtService')
  opdrachtService: OpdrachtService = new OpdrachtService(this.http);

  @Provide('schildeelService')
  schildeelService: SchildeelService = new SchildeelService(this.http);

  @Provide('certificaatService')
  certificaatService: CertificaatService = new CertificaatService(this.http);

  @Provide('downloadService')
  downloadService: DownloadService = new DownloadService(this.http);

  @Provide('localStorageService')
  localStorageService: LocalStorageService = new LocalStorageService();

  @Provide('certificatenTableHeaderProvider')
  certificatenTableHeaderProvider: CertificatenTableHeaderProvider = new CertificatenTableHeaderProvider(
    this.localStorageService
  );

  @Provide('gebouweenheidService')
  gebouweenheidService: GebouweenheidService = new GebouweenheidService(this.http);

  @Provide('gebouwService')
  gebouwService: GebouwService = new GebouwService(this.http);

  @Provide('gebruikerService')
  gebruikerService: GebruikerService = new GebruikerService(this.http);

  @Provide('copyPasteGebouweenheidService')
  copyPasteGebouweenheidService: CopyPasteGebouweenheidService = new CopyPasteGebouweenheidService();

  @Provide('filterService')
  filterService: FilterService = new FilterService();

  @Loader.Getter
  public getLoading!: boolean;

  @Loader.Getter
  public getLoadingMessage!: string;

  @Errors.Getter('getErrors')
  public errors!: string[];

  @SuccessAlertMessages.Getter('getMessages')
  public messages!: string[];

  @WarningAlertMessages.Getter('getWarnings')
  public warnings!: string[];
}
